import Cookies from 'universal-cookie';
import requestInstance from './axios';

const cookies = new Cookies();

const categoriesAPI = {
  async getTypes() {
    const locale = cookies.get('locale') || 'am';

    const res = await requestInstance(false).get(`/types`, {
      params: { locale },
    });

    return res.data;
  },

  async getEvents() {
    const locale = cookies.get('locale') || 'am';

    const res = await requestInstance(false).get(`/events`, {
      params: { locale },
    });

    return res.data;
  },

  async getRelationships() {
    const locale = cookies.get('locale') || 'am';

    const res = await requestInstance(false).get(`/relationships`, {
      params: { locale },
    });

    return res.data;
  },
};

export default categoriesAPI;
