import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Facebook from '../../../Helpers/icons/Facebook';
import Instagram from '../../../Helpers/icons/Instagram';
import { PUBLIC_URL } from '../../../../utils/constants';
import LinkedIn from '../../../Helpers/icons/LinkedIn';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className='footer-section-wrapper bg-white print:hidden'>
      <div className='w-[100%] max-w-[1216px] block mx-auto pt-[40px] md:pt-[56px] px-[16px]'>
        <div className='w-full flex flex-col items-center mb-[24px]'>
          <div className='mb-[40px]'>
            <Link to='/' className='main-logo flex items-center gap-[14px]'>
              <img
                width='48'
                height='48'
                className='mt-[-10px]'
                src={`${PUBLIC_URL}/assets/images/logo.svg`}
                alt='logo'
              />
              <div className='logo-text'>
                <h5 className='text-[20px] font-semibold mb-[9px]'>E-Gift Cards</h5>
                <p className='text-[8px] font-medium'>
                  The first digital gift cards platform in Armenia
                </p>
              </div>
            </Link>
          </div>
          <div className='w-full h-[1px] bg-[#E9E9E9]'></div>
        </div>
        <div className='lg:flex justify-between mb-[24px]'>
          <div className='lg:w-[424px] ml-0 w-full mb-[24px] lg:mb-0'>
            <h2 className='text-base md:text-lg font-normal md:font-medium text-[#2F2F2F] mb-[16px]'>
              {t('about_us')}
            </h2>
            <p className='text-[#9A9A9A] text-[14px] w-[247px] leading-[28px]'>
              {t('about_us_description.title')}
            </p>

            <Link
              className='block hover:underline text-neutral-500 text-[14px] my-[4px]'
              to='/privacy-policy'
            >
              {t('privacy_policy')}
            </Link>
            <Link
              className='block hover:underline text-neutral-500 text-[14px]'
              to='/terms-of-services'
            >
              {t('terms_of_service')}
            </Link>
          </div>
          <div className='flex-1 lg:flex mb-[24px] lg:mb-0'>
            <div className='lg:w-1/2 w-full'></div>

            <div className='lg:w-1/2 lg:flex lg:flex-col items-center w-full'>
              <div>
                <h6 className='text-base md:text-lg font-normal md:font-medium text-[#2F2F2F] mb-[16px]'>
                  {t('contact_us')}
                </h6>

                <div>
                  <ul className='flex flex-col gap-[8px]'>
                    <li>
                      <a href='tel:+37493773701'>
                        <span className='text-[#9A9A9A] text-[14px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer'>
                          +374 93 773 701
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href='mailto:info@egiftcards.am'>
                        <span className='text-[#9A9A9A] text-[14px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer'>
                          info@egiftcards.am
                        </span>
                      </a>
                    </li>
                    <li>
                      <span className='text-[#9A9A9A] text-[14px]'>{t('our_address')}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='bottom-bar border-t border-qgray-border py-[16px] md:py-[6px] lg:flex justify-between items-center'>
          <div className='w-full flex lg:space-x-5 justify-between items-center'>
            <span className='text-xs text-qgray font-light'>
              © {new Date().getFullYear()} {t('online_gift_cards')}
            </span>
            <div className='flex items-center gap-[10px]'>
              <a href={process.env.REACT_APP_INSTAGRAM_URL} target='_blank' rel='noreferrer'>
                <Instagram size='20' className='fill-current text-qgray hover:text-qblack' />
              </a>
              <a href={process.env.REACT_APP_FACEBOOK_URL} target='_blank' rel='noreferrer'>
                <Facebook size='20' className='fill-current text-qgray hover:text-qblack' />
              </a>
              <a href={process.env.REACT_APP_LINKEDIN_URL} target='_blank' rel='noreferrer'>
                <LinkedIn size='20' className='fill-current text-qgray hover:text-qblack' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
