import Cookies from 'universal-cookie';
import { Response, SpinWheelResponse } from '../types/types';
import requestInstance from './axios';

const cookies = new Cookies();

const spinWheelAPI = {
  async getWheelItems() {
    const locale = cookies.get('locale') || 'am';
    const anonymousUserInCookie = cookies.get('anonymousUser');

    const res = await requestInstance().get<Response<Array<string | null>>>('/spin', {
      params: { anonymous_user_id: anonymousUserInCookie.anonymous_user_id, locale },
    });

    return res.data;
  },
  async sendResult(result: string | null) {
    const locale = cookies.get('locale') || 'am';
    const anonymousUserInCookie = cookies.get('anonymousUser');

    const res = await requestInstance().post<SpinWheelResponse>('/spin', {
      result,
      anonymous_user_id: anonymousUserInCookie.anonymous_user_id,
      locale,
    });

    return res.data;
  },
  async sendEmail(email: string, coupon: string) {
    const locale = cookies.get('locale') || 'am';
    const data = { email, coupon };
    const res = await requestInstance(false).post<{ message: string }>(
      '/coupons/send-to-email',
      data,
      {
        params: { locale },
      }
    );

    return res.data;
  },
};

export default spinWheelAPI;
