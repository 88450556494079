// reactstrap components
import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import Arrow from '../icons/Arrow';
import './style.css';

const locales = {
  am: { name: 'Հայերեն', image: `${process.env.PUBLIC_URL}/assets/images/flags/armenia.png` },
  en: { name: 'English', image: `${process.env.PUBLIC_URL}/assets/images/flags/uk.png` },
  ru: { name: 'Русский', image: `${process.env.PUBLIC_URL}/assets/images/flags/russia.png` },
};
const cookies = new Cookies();

export type Locale = 'am' | 'ru' | 'en';

export const getLocale = (defaultLocale: Locale) => {
  if (window) {
    const storegeLocal = defaultLocale || cookies.get('locale');

    if (storegeLocal && storegeLocal in locales) {
      const key: Locale = storegeLocal;

      return locales[key];
    }
  }

  return locales.am;
};

const LanguagePicker = () => {
  const { i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultLocale = searchParams.get('locale');
  const locale = useRef<Locale>('am');

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    let siteLocale = 'am';
    if (window) {
      const storegeLocal = defaultLocale || cookies.get('locale');

      if (storegeLocal && storegeLocal in locales) {
        const key = storegeLocal;

        siteLocale = key;
      }
    }

    locale.current = siteLocale as Locale;
  }, []);

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    if (defaultLocale) {
      setSearchParams({ locale: '' });
    }

    cookies.set('locale', lang, {
      path: '/',
      expires: new Date(Date.now() + 60 * 86400000),
    });

    window.location.reload();
  };

  return (
    <div className='my-select-box w-fit'>
      <button
        onClick={() => setToggle(!toggle)}
        type='button'
        className='my-select-box-btn h-[100%] flex items-center gap-[5px]'
      >
        <img src={locales[locale.current].image} width={16} height={16} alt='' />
        <span>{locales[locale.current].name}</span>
        <Arrow className='fill-current qblack' />
      </button>
      {toggle && <div className='click-away' onClick={() => setToggle(!toggle)}></div>}
      <div className={`my-select-box-section ${toggle ? 'open' : ''}`}>
        <ul className='list'>
          <li onClick={() => changeLanguage('am')} className='flex items-center gap-[10px]'>
            <img src={locales.am.image} width={16} height={16} alt='' />
            <span>Հայերեն</span>
          </li>

          <li onClick={() => changeLanguage('en')} className='flex items-center gap-[10px]'>
            <img src={locales.en.image} width={16} height={16} alt='' />
            English
          </li>

          <li onClick={() => changeLanguage('ru')} className='flex items-center gap-[10px]'>
            <img src={locales.ru.image} width={16} height={16} alt='' />
            Русский
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LanguagePicker;
