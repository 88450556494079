import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/hooks';
import ThinPeople from '../../Helpers/icons/ThinPeople';
import LoopIcon from '../../Helpers/icons/LoopIcon';
import Middlebar from './Middlebar';
import MobileSearchBox from '../../Helpers/SearchBox/MobileSearchBox';
import Navbar from './Navbar';
import TopBar from './TopBar';
import XIcon from '../../Helpers/icons/XIcon';
import { PUBLIC_URL } from '../../../utils/constants';
import './header.css';

type PropsType = {
  className?: string;
  drawerAction: () => void;
  events: {
    id: number;
    name: string;
  }[];
};

const Header = ({ className, drawerAction, events }: PropsType) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector((state) => state.profile.user);
  const { t } = useTranslation();
  const [showSearch, setShowSearch] = useState(false);

  const handleSearch = async (value: string) => {
    navigate(`/products?keyword=${value}`);
  };

  const showSearchButton = !showSearch && location.pathname !== '/products';
  const showSearchBox = showSearch && location.pathname !== '/products';

  return (
    <header className={`${className || ''} header-section-wrapper relative`}>
      <TopBar className='quomodo-shop-top-bar' />
      <Middlebar
        isAuth={!!user}
        name={user?.name}
        className='quomodo-shop-middle-bar lg:block hidden'
      />
      <div className='quomodo-shop-drawer lg:hidden block w-full bg-white'>
        <div className='w-full h-full flex justify-between items-center md:px-5 px-2'>
          <div onClick={drawerAction} className='w-[90px] mr-[6px]'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              strokeWidth='2'
            >
              <path strokeLinecap='round' strokeLinejoin='round' d='M4 6h16M4 12h16M4 18h7' />
            </svg>
          </div>

          <div>
            <Link to='/' className='main-logo'>
              <img width='40' height='40' src={`${PUBLIC_URL}/assets/images/logo.svg`} alt='logo' />
              <div className='logo-text mt-[14px]'>
                <h5 className='text-[20px] font-semibold mb-[9px]'>E-Gift Cards</h5>
                <p className='text-[8px] font-medium'>
                  The first digital gift cards platform in Armenia
                </p>
              </div>
            </Link>
          </div>

          <div className='flex items-center w-[90px] ml-[6px] justify-end'>
            {showSearchButton && (
              <span className='w-[16px] h-[16px] mr-[14px]' onClick={() => setShowSearch(true)}>
                <LoopIcon />
              </span>
            )}
            {user ? (
              <Link to='/profile'>
                <span>
                  <ThinPeople />
                </span>
              </Link>
            ) : (
              <div className='flex items-center gap-3'>
                <Link to='/login'>{t('sign_in')}</Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <Navbar events={events} className='quomodo-shop-nav-bar lg:block hidden' />
      {showSearchBox && (
        <div className='lg:hidden flex items-center max-w-[420px] w-full h-full mx-auto py-[12px] px-[16px] gap-[14px]'>
          <MobileSearchBox handleSearch={handleSearch} />
          <span className='w-[18px] h-[18px]' onClick={() => setShowSearch(false)}>
            <XIcon />
          </span>
        </div>
      )}
    </header>
  );
};

export default Header;
