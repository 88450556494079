import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

type PropsType = {
  className?: string;
  open: boolean;
  action: () => void;
  events: {
    id: number;
    name: string;
  }[];
};

export default function Drawer({ className, open, action, events }: PropsType) {
  const [tab, setTab] = useState('category');
  const { t } = useTranslation();

  return (
    <>
      <div className={`drawer-wrapper w-full  h-full relative  ${className || ''}`}>
        {open && (
          <div
            onClick={action}
            className='w-full h-screen bg-black bg-opacity-40 z-40 left-0 top-0 fixed'
          ></div>
        )}
        <div
          className={`w-[280px] transition-all duration-300
           ease-in-out h-screen overflow-y-auto overflow-x-hidden 
           overflow-style-none bg-white fixed top-0 z-50 ${open ? 'left-0' : '-left-[280px]'}`}
        >
          <div className='w-full mt-5 px-5 flex items-center space-x-3'>
            <span
              onClick={() => setTab('category')}
              className={`text-base cursor-pointer font-semibold  ${
                tab === 'category' ? 'text-qblack' : 'text-qgray'
              }`}
            >
              {t('event')}
            </span>
            <span className='w-[1px] h-[14px] bg-qgray'></span>
            <span
              onClick={() => setTab('menu')}
              className={`text-base cursor-pointer font-semibold ${
                tab === 'menu' ? 'text-qblack' : 'text-qgray '
              }`}
            >
              {t('main_menu')}
            </span>
          </div>
          {tab === 'category' ? (
            <div key='category' className='category-item mt-5 w-full'>
              <ul className='categories-list'>
                {events.map((item) => (
                  <li key={item.id} className='category-item'>
                    <a href={`/products?events=${item.id}`} onClick={() => action()}>
                      <div className=' flex justify-between items-center px-5 h-12 bg-white hover:bg-qyellow transition-all duration-300 ease-in-out cursor-pointer'>
                        <div className='flex items-center space-x-6'>
                          <span className='text-sm font-400'>{item.name}</span>
                        </div>

                        <div>
                          <span>
                            <svg
                              width='6'
                              height='9'
                              viewBox='0 0 6 9'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <rect
                                x='1.49805'
                                y='0.818359'
                                width='5.78538'
                                height='1.28564'
                                transform='rotate(45 1.49805 0.818359)'
                                fill='#1D1D1D'
                              />
                              <rect
                                x='5.58984'
                                y='4.90918'
                                width='5.78538'
                                height='1.28564'
                                transform='rotate(135 5.58984 4.90918)'
                                fill='#1D1D1D'
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div key='menu' className='menu-item mt-5 w-full'>
              <ul className='categories-list'>
                <li className='category-item'>
                  <NavLink to='/'>
                    <div className=' flex justify-between items-center px-5 h-12 bg-white hover:bg-qyellow transition-all duration-300 ease-in-out cursor-pointer'>
                      <div className='flex items-center space-x-6'>
                        <span className='text-sm font-400'>{t('home')}</span>
                      </div>
                      <div>
                        <span>
                          <svg
                            width='6'
                            height='9'
                            viewBox='0 0 6 9'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <rect
                              x='1.49805'
                              y='0.818359'
                              width='5.78538'
                              height='1.28564'
                              transform='rotate(45 1.49805 0.818359)'
                              fill='#1D1D1D'
                            />
                            <rect
                              x='5.58984'
                              y='4.90918'
                              width='5.78538'
                              height='1.28564'
                              transform='rotate(135 5.58984 4.90918)'
                              fill='#1D1D1D'
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </NavLink>
                </li>

                <li className='category-item'>
                  <NavLink to='/products'>
                    <div className='flex justify-between items-center px-5 h-12 bg-white hover:bg-qyellow transition-all duration-300 ease-in-out cursor-pointer'>
                      <div className='flex items-center space-x-6'>
                        <span className='text-sm font-400'>{t('gift_cards')}</span>
                      </div>
                      <div>
                        <span>
                          <svg
                            width='6'
                            height='9'
                            viewBox='0 0 6 9'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <rect
                              x='1.49805'
                              y='0.818359'
                              width='5.78538'
                              height='1.28564'
                              transform='rotate(45 1.49805 0.818359)'
                              fill='#1D1D1D'
                            />
                            <rect
                              x='5.58984'
                              y='4.90918'
                              width='5.78538'
                              height='1.28564'
                              transform='rotate(135 5.58984 4.90918)'
                              fill='#1D1D1D'
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </NavLink>
                </li>
                <li className='category-item'>
                  <NavLink to='/companies'>
                    <div className='flex justify-between items-center px-5 h-12 bg-white hover:bg-qyellow transition-all duration-300 ease-in-out cursor-pointer'>
                      <div className='flex items-center space-x-6'>
                        <span className='text-sm font-400'>{t('companies')}</span>
                      </div>
                      <div>
                        <span>
                          <svg
                            width='6'
                            height='9'
                            viewBox='0 0 6 9'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <rect
                              x='1.49805'
                              y='0.818359'
                              width='5.78538'
                              height='1.28564'
                              transform='rotate(45 1.49805 0.818359)'
                              fill='#1D1D1D'
                            />
                            <rect
                              x='5.58984'
                              y='4.90918'
                              width='5.78538'
                              height='1.28564'
                              transform='rotate(135 5.58984 4.90918)'
                              fill='#1D1D1D'
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </NavLink>
                </li>
                <li className='category-item'>
                  <NavLink to='/contact'>
                    <div className='flex justify-between items-center px-5 h-12 bg-white hover:bg-qyellow transition-all duration-300 ease-in-out cursor-pointer'>
                      <div className='flex items-center space-x-6'>
                        <span className='text-sm font-400'>{t('contact')}</span>
                      </div>
                      <div>
                        <span>
                          <svg
                            width='6'
                            height='9'
                            viewBox='0 0 6 9'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <rect
                              x='1.49805'
                              y='0.818359'
                              width='5.78538'
                              height='1.28564'
                              transform='rotate(45 1.49805 0.818359)'
                              fill='#1D1D1D'
                            />
                            <rect
                              x='5.58984'
                              y='4.90918'
                              width='5.78538'
                              height='1.28564'
                              transform='rotate(135 5.58984 4.90918)'
                              fill='#1D1D1D'
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </NavLink>
                </li>

                <li className='category-item'>
                  <NavLink to='/about'>
                    <div className='flex justify-between items-center px-5 h-12 bg-white hover:bg-qyellow transition-all duration-300 ease-in-out cursor-pointer'>
                      <div className='flex items-center space-x-6'>
                        <span className='text-sm font-400'>{t('about_us')}</span>
                      </div>
                      <div>
                        <span>
                          <svg
                            width='6'
                            height='9'
                            viewBox='0 0 6 9'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <rect
                              x='1.49805'
                              y='0.818359'
                              width='5.78538'
                              height='1.28564'
                              transform='rotate(45 1.49805 0.818359)'
                              fill='#1D1D1D'
                            />
                            <rect
                              x='5.58984'
                              y='4.90918'
                              width='5.78538'
                              height='1.28564'
                              transform='rotate(135 5.58984 4.90918)'
                              fill='#1D1D1D'
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </NavLink>
                </li>

                <li className='category-item'>
                  <NavLink to='/become-seller'>
                    <div className=' flex justify-between items-center px-5 h-12 bg-white hover:bg-qyellow transition-all duration-300 ease-in-out cursor-pointer'>
                      <div className='flex items-center space-x-6'>
                        <span className='text-sm font-400'>{t('become_a_partner')}</span>
                      </div>
                      <div>
                        <span>
                          <svg
                            width='6'
                            height='9'
                            viewBox='0 0 6 9'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <rect
                              x='1.49805'
                              y='0.818359'
                              width='5.78538'
                              height='1.28564'
                              transform='rotate(45 1.49805 0.818359)'
                              fill='#1D1D1D'
                            />
                            <rect
                              x='5.58984'
                              y='4.90918'
                              width='5.78538'
                              height='1.28564'
                              transform='rotate(135 5.58984 4.90918)'
                              fill='#1D1D1D'
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </NavLink>
                </li>
                <li className='category-item'>
                  <NavLink to='/request-company'>
                    <div className=' flex justify-between items-center px-5 h-12 bg-white hover:bg-qyellow transition-all duration-300 ease-in-out cursor-pointer'>
                      <div className='flex items-center space-x-6'>
                        <span className='text-sm font-400'>{t('request_company')}</span>
                      </div>
                      <div>
                        <span>
                          <svg
                            width='6'
                            height='9'
                            viewBox='0 0 6 9'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <rect
                              x='1.49805'
                              y='0.818359'
                              width='5.78538'
                              height='1.28564'
                              transform='rotate(45 1.49805 0.818359)'
                              fill='#1D1D1D'
                            />
                            <rect
                              x='5.58984'
                              y='4.90918'
                              width='5.78538'
                              height='1.28564'
                              transform='rotate(135 5.58984 4.90918)'
                              fill='#1D1D1D'
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
