import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PrimaryPopup from '../popups/PrimaryPopup';
import { logout } from '../../store/profileReducer';
import { useAppDispatch } from '../../hooks/hooks';

type Props = {
  onClose: () => void;
};

const LogoutPopup = ({ onClose }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setisLoading] = useState(false);

  const handleLogout = () => {
    setisLoading(true);
    dispatch(logout())
      .then(() => {
        navigate('/');
      })
      .catch(() => false)
      .finally(() => {
        setisLoading(false);
      });
  };

  return (
    <PrimaryPopup
      className='w-[500px] sm:max-w-[500px] sm:w-full'
      onClose={onClose}
      title={t('log_out')}
    >
      <div className='w-full pb-[32px] pt-3'>
        <h2>{t('are_you_sure_logout')}</h2>
        <div className='flex w-full justify-between mt-[32px]'>
          <button className='px-4 py-2 border hover:bg-qblack hover:text-white' onClick={onClose}>
            {t('cancel')}
          </button>
          <button
            className='px-4 py-2 bg-qblack text-white'
            disabled={isLoading}
            onClick={handleLogout}
          >
            {t('submit')}
          </button>
        </div>
      </div>
    </PrimaryPopup>
  );
};

export default LogoutPopup;
