import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useAppDispatch, useAppSelector } from './hooks/hooks';
import Routers from './Routers';
import { getProfile } from './store/profileReducer';
import useAnonymousUser from './hooks/useAnonymousUser';
import { locales } from './index';

const cookies = new Cookies();

function App() {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  useAnonymousUser();

  const user = useAppSelector((state) => state.profile.user);
  const [token, setToken] = useState(cookies.get('token'));

  useEffect(() => {
    const localeParam = searchParams.get('locale');
    if (localeParam) {
      cookies.set('locale', localeParam, {
        path: '/',
        expires: new Date(Date.now() + 60 * 86400000),
      });
    }
    const locale = cookies.get('locale');

    if (locale && locales.includes(locale)) {
      i18n.changeLanguage(locale);
    }
  }, []);

  useEffect(() => {
    const tokenInCookie = cookies.get('token');
    if (!tokenInCookie) {
      setToken(null);
    } else if (token && !user) {
      dispatch(getProfile()).finally(() => {
        setToken(null);
      });
    }
  }, [token, user]);

  return <Routers isLoading={!user && token} isAuth={!!user} />;
}

export default App;
