import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import LoadingScreen from './components/LoadingScreen';
import Success from './components/Success';
import Failure from './components/Failure';

const AllProductPage = lazy(() => import('./components/AllProductPage'));
const AllCompaniesPage = lazy(() => import('./components/AllCompaniesPage'));
const ForgotPassword = lazy(() => import('./components/Auth/ForgotPassword/index'));
const Login = lazy(() => import('./components/Auth/Login/index'));
const Profile = lazy(() => import('./components/Auth/Profile'));
const Signup = lazy(() => import('./components/Auth/Signup'));
const BecomeSeller = lazy(() => import('./components/BecomeSeller'));
const RequestCompany = lazy(() => import('./components/RequestCompany'));
const Contact = lazy(() => import('./components/Contact'));
const About = lazy(() => import('./components/About'));
const FourZeroFour = lazy(() => import('./components/FourZeroFour'));
const Home = lazy(() => import('./components/Home'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const SingleProductPage = lazy(() => import('./components/SingleProductPage'));
const TermsOfServices = lazy(() => import('./components/TermsOfServices/index'));
const AddPersonalDocuments = lazy(
  () => import('./components/AddPersonalDocuments/AddPersonalDocuments')
);
const ResetPassword = lazy(() => import('./components/Auth/ForgotPassword/ResetPassword'));
const Company = lazy(() => import('./components/Company'));
const SMSGiftcard = lazy(() => import('./components/SMSGiftcard'));
type PropsType = {
  isLoading: boolean;
  isAuth: boolean;
};

export default function Routers({ isLoading, isAuth }: PropsType) {
  if (isLoading) return <LoadingScreen />;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/companies' element={<AllCompaniesPage />} />
        <Route path='/company/:companySlug' element={<Company />} />
        <Route path='/products/:productSlug' element={<SingleProductPage />} />
        <Route path='/products' element={<AllProductPage />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/about' element={<About />} />
        <Route path='/become-seller' element={<BecomeSeller />} />
        <Route path='/request-company' element={<RequestCompany />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-of-services' element={<TermsOfServices />} />
        <Route path='/success' element={<Success />} />
        <Route path='/personal-documents' element={<AddPersonalDocuments />} />
        <Route path='/failure' element={<Failure />} />
        <Route path='/g/:hash' element={<SMSGiftcard />} />
        {isAuth && <Route path='/profile' element={<Profile />} />}
        {!isAuth ? (
          <>
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/auth/reset-password' element={<ResetPassword />} />
          </>
        ) : (
          <Route path='*' element={<FourZeroFour />} />
        )}
        <Route path='*' element={<FourZeroFour />} />
      </Routes>
    </Suspense>
  );
}
