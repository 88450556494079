import Cookies from 'universal-cookie';
import requestInstance from './axios';

const cookies = new Cookies();

const anonymousUserAPI = {
  async storeUserData(data: { [key: string]: string }) {
    const locale = cookies.get('locale') || 'am';
    const res = await requestInstance(false).post('/anonymous-users', data, { params: { locale } });

    return res.data;
  },
};

export default anonymousUserAPI;
