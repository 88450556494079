import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Arrow from '../../Helpers/icons/Arrow';

type PropsType = {
  className?: string;
  events: {
    id: number;
    name: string;
  }[];
};

export default function Navbar({ className, events }: PropsType) {
  const { t } = useTranslation();
  const [categoryToggle, setToggle] = useState(false);
  const [elementsSize, setSize] = useState('0px');
  const [showPartnership, setShowPartnership] = useState(false);

  const handler = () => {
    setToggle(!categoryToggle);
  };

  const showPartnershipHandler = () => {
    setShowPartnership(!showPartnership);
  };

  useEffect(() => {
    if (categoryToggle) {
      const getItems = document.querySelectorAll(`.categories-list li`).length;
      if (categoryToggle && getItems > 0) {
        setSize(`${42 * getItems}px`);
      }
    } else {
      setSize(`0px`);
    }
  }, [categoryToggle]);

  return (
    <div className={`nav-widget-wrapper w-full h-[60px] relative z-30 bg-white ${className || ''}`}>
      <div className='container-x mx-auto h-full'>
        <div className='w-full h-full relative'>
          <div className='w-full h-full flex justify-between items-center'>
            <div className='category-and-nav flex xl:space-x-7 space-x-3 items-center'>
              <div className='category w-[270px] h-[53px] bg-white px-5 rounded-t-md mt-[6px] relative'>
                <button
                  onClick={handler}
                  type='button'
                  className='w-full h-full flex justify-between items-center'
                >
                  <div className='flex space-x-3 items-center'>
                    <span>
                      <svg
                        className='fill-current'
                        width='14'
                        height='9'
                        viewBox='0 0 14 9'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect width='14' height='1' />
                        <rect y='8' width='14' height='1' />
                        <rect y='4' width='10' height='1' />
                      </svg>
                    </span>
                    <span className='text-sm font-600 text-qblacktext'>{t('event')}</span>
                  </div>
                  <div>
                    <Arrow
                      width='5.78538'
                      height='1.28564'
                      className='fill-current text-qblacktext'
                    />
                  </div>
                </button>
                {categoryToggle && (
                  <div className='fixed top-0 left-0 w-full h-full -z-10' onClick={handler}></div>
                )}
                {showPartnership && (
                  <div
                    className='fixed top-0 left-0 w-full h-full -z-10'
                    onClick={showPartnershipHandler}
                  ></div>
                )}
                <div
                  className='category-dropdown w-full absolute left-0 top-[53px] overflow-hidden'
                  style={{ height: `${elementsSize} ` }}
                >
                  <ul className='categories-list'>
                    {events.map((item) => (
                      <li key={item.id} className='category-item'>
                        <a href={`/products?events=${item.id}`}>
                          <div
                            className={`flex justify-between
                             items-center px-5 h-10 bg-white
                              transition-all duration-300 ease-in-out cursor-pointer text-qblack hover:bg-qyellow`}
                          >
                            <div className='flex items-center'>
                              <span className='text-xs font-400'>{item.name}</span>
                            </div>
                            <div>
                              <span>
                                <svg
                                  className='fill-current'
                                  width='6'
                                  height='9'
                                  viewBox='0 0 6 9'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <rect
                                    x='1.49805'
                                    y='0.818359'
                                    width='5.78538'
                                    height='1.28564'
                                    transform='rotate(45 1.49805 0.818359)'
                                  />
                                  <rect
                                    x='5.58984'
                                    y='4.90918'
                                    width='5.78538'
                                    height='1.28564'
                                    transform='rotate(135 5.58984 4.90918)'
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className='nav'>
                <ul className='nav-wrapper flex xl:space-x-10 space-x-5'>
                  <li>
                    <Link to='/products'>
                      <span className='flex items-center text-sm font-600 cursor-pointer text-qblacktext'>
                        <span>{t('gift_cards')}</span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/companies'>
                      <span className='flex items-center text-sm font-600 cursor-pointer text-qblacktext'>
                        <span>{t('companies')}</span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/about'>
                      <span className='flex items-center text-sm font-600 cursor-pointer text-qblacktext'>
                        <span>{t('about_us')}</span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/contact'>
                      <span className='flex items-center text-sm font-600 cursor-pointer text-qblacktext'>
                        <span>{t('contact')}</span>
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='become-seller-btn relative'>
              <div
                onClick={showPartnershipHandler}
                className='black-btn px-[12px] w-[auto] h-[40px] flex justify-center items-center cursor-pointer'
              >
                <div className='flex space-x-2 items-center'>
                  <span className='text-sm font-600 select-none'>{t('partnership')}</span>
                  <span>
                    <svg
                      className='fill-current'
                      width='6'
                      height='10'
                      viewBox='0 0 6 10'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        x='1.08984'
                        width='6.94106'
                        height='1.54246'
                        transform='rotate(45 1.08984 0)'
                        fill='white'
                      />
                      <rect
                        x='6'
                        y='4.9082'
                        width='6.94106'
                        height='1.54246'
                        transform='rotate(135 6 4.9082)'
                        fill='white'
                      />
                    </svg>
                  </span>
                </div>
              </div>
              {showPartnership && (
                <div
                  className='absolute bottom-[-90px] right-0 bg-white z-50 p-2 rounded-xl'
                  style={{ boxShadow: '0px 15px 64px 0px rgba(0, 0, 0, 0.2)' }}
                >
                  <Link
                    className='block px-2 py-[5px] hover:bg-[#ededed] rounded-lg whitespace-nowrap'
                    to='/become-seller'
                  >
                    {t('become_a_partner')}
                  </Link>
                  <Link
                    className='block px-2 py-[5px] hover:bg-[#ededed] rounded-lg whitespace-nowrap'
                    to='/request-company'
                  >
                    {t('request_company')}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
