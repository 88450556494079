export const privacy_policy_content = {
  general_overview: 'Ընդհանուր ակնարկ',
  general_overview_text: `egiftcards.am-ում մեզ համար կարևոր է Ձեր անձնական տվյալների գաղտնիության պահպանումը:
  Այդ պատճառով, մենք պարտավորվում ենք պաշտպանել մեր կայքի և մեր կիրառական ծրագրի բոլոր
  այցելուների և օգտատերերի անձնական տվյալների գաղտնիությունը («Ծառայություններ»):
  Գաղտնիության այս քաղաքականության միջոցով («Գաղտնիության քաղաքականություն») մենք
  ներկայացնում ենք, թե ինչպես ենք մենք հավաքում Ձեր տվյալները, ինչու են դրանք մեզ
  անհրաժեշտ և ինչպես ենք մենք օգտագործում դրանք: Խնդրում ենք ուշադիր կարդալ այս
  գաղտնիության քաղաքականությունը, քանի որ այն պարունակում է կարևոր տեղեկություններ այն
  մասին, թե ինչպես ենք մենք մշակում Ձեր կողմից մեզ տրամադրված ցանկացած անձնական տվյալ:`,
  information_we_collect: 'Մեր հավաքած տեղեկատվությունը և Ձեր տվյալները օգտագործելու եղանակը',
  information_we_collect_text: `Մենք հավաքում ենք միայն այն տեղեկատվությունը, որն անհրաժեշտ է Մեր ծառայությունների
  մատուցման և կատարելագործման համար: Մենք օգտագործում ենք Ձեր տվյալները մեր
  Ծառայությունները կատարելագործելու և Ձեր նախապատվություններին հարմարեցնելու համար: Սա
  անհրաժեշտ է, որ մենք հնարավորություն ունենանք օրինական հիմունքներով պարզելու, թե
  ինչպես են օգտագործվում մեր Ծառայությունները, ինչպես նաև մեր բիզնեսը զարգացնելու և
  ընդլայնելու ուղիներ գտնելու ու կիրառելու նպատակով: Դա նաև անհրաժեշտ է, որ մենք
  կարողանանք իրականացնել մեր օրինական շահերը, որոնք ուղված են մեր Ծառայությունների
  բարելավելմանը, արդյունավետության, Ծառայությունների նկատմամբ օգտատերերի հետաքրքրության
  բարձրացմանը, ինչպես նաև մեր Ծառայությունների օգտագործման ձևերի վերաբերյալ
  պատկերացումներ ձեռք բերելուն: egiftcards.am-ում համապատասխան տեղեկատվություն և
  առաջարկվող ապրանքներ ու ծառայություններ որոնելու և գտնելու հարցում Ձեզ օգնելու, այդ
  թվում վաճառողներին իրենց արտադրանքը շուկայահանելու և վաճառելու համար լավագույն ուղիներ
  գտնելու համար այդ տեղեկատվության օգտագործելու նպատակով մեր Ծառայությունների տրամադրման
  ընթացքում Ձեր անհատական պահանջներին հարմարվելը խիստ էական է մեզ համար:`,
  to_send_you_information_about_improvements:
    'Ձեզ համար մշակված բարելավումների և նոր ծառայությունների մասին տեղեկություններուղարկելու համար.',
  to_send_you_information_about_improvements_text: `Նոր ապրանքների, ծառայությունների, առաջխաղացումների մասին Ձեզ տեղեկացնելու և մեր
  կարծիքով Ձեզ համար հետաքրքիր տեղեկություններ տրամադրելու համար մենք կարող ենք
  ուղղակիորեն էլեկտրոնային փոստով և այլ եղանակով Ձեզ ուղարկել հաղորդագրություններ և
  նյութեր:`,
  for_the_safety_and_security: 'Մեր ծառայությունների անվտանգության և ապահովության համար.',
  for_the_safety_and_security_text: `Մենք օգտագործում ենք Ձեր տեղեկությունները անվտանգության և ապահովության նպատակներով,
  ինչը կարող է ներառել նման նպատակներով Ձեր տվյալների տրամադրումը: Մենք դա անում ենք,
  քանի որ դա անհրաժեշտ է մեր Ծառայությունների անվտանգությունը ապահովելու, անհատներին կամ
  գույքին վնաս պատճառելը կամ հանցագործությունը կանխելու, օրինական իրավունքները
  իրականացնելու կամ պաշտպանելու, կամ մեր համակարգերին կամ օգտատերերին վնաս պատճառելը
  կանխելու կամ երրորդ կողմի օրինական շահերը պաշտպանելու նպատակով: Սա ներառում է մեր
  համայնքի պաշտպանությունը սպամից, խարդախությունից, ոտնձգություններից, մտավոր
  սեփականության ոտնահարումից, հանցագործություններից և բոլոր տեսակի անվտանգային ռիսկերից:
  Մենք օգտագործում ենք Ձեր տվյալները Ծառայությունների տրամադրման և բարելավման, հաշիվների
  դուրսգրման և վճարումների ապահովման, ինքնության հաստատման ու նույնականացման, ինչպես նաև
  ընդհանուր ուսումնասիրությունների և ամփոփ հաշվետվությունների կազմման նպատակով:`,
  information_collected_about_you: 'Ձեր մասին հավաքվող տվյալներ',
  information_collected_about_you_text: `Երբ դուք օգտվում եք Ծառայություններից կամ լոգին ու գաղտնաբառ եք ստեղծում
  Ծառայություններին հասանելիություն ստանալու համար, Ձեզ կարող են խնդրել տեղեկություններ
  տրամադրել Ձեր մասին, այդ թվում տրամադրել (i) Ձեր անունը և կոնտակտային տվյալները,
  ներառյալ էլ.փոստի հասցեն և հեռախոսահամարները. (ii) տեղեկատվություն, որը թույլ կտա մեզ
  ստուգել և հաստատել Ձեր ինքնությունը. (iii) տվյալներ գտնվելու վայրի մասին. (iv)
  տվյալներ հաշիվներ դուրս գրելու և վճարումներ ապահովելու համար. և (v) այնպիսի այլ
  տվյալներ, որոնք մենք կարող ենք ժամանակ առ ժամանակ պահանջվել Ծառայությունները
  տրամադրելու և գործող օրենքներին համապատասխանելու համար: Այս տեղեկատվությունը
  պահանջվում է Ձեզ Ծառայություններ տրամադրելու համար: Եթե Դուք նման տեղեկատվություն
  չտրամադրեք, դա կարող է հանգեցնել մեր կողմից Ծառայությունների մատուցման ուշացմանը կամ
  խոչընդոտելուն:`,
  who_do_we_share_your_personal_data_with: 'Ում ենք մենք տրամադրում ենք Ձեր անձնական տվյալները',
  who_do_we_share_your_personal_data_with_text: `Մենք կարող ենք վերը նշված բոլոր տվյալները կամ դրանց մի մասը տրամադրել հետևյալ
  հանգամանքներում. խուզարկության օրդերի, դատարանի որոշման կամ այլ դատական հրամանի հիման
  վրա. egiftcards.am-ի օգտատերերին, egiftcards.am-ի կամ լայն հանրության իրավունքները,
  գույքը կամ անվտանգությունը պաշտպանելու նպատակով. Ձեր ցուցումով (օրինակ՝ եթե Դուք մեզ
  լիազորում եք տվյալներ տրամադրել այլ օգտատերերին):`,
  the_data_we_store_and_process: 'Տվյալները, որոնք մենք պահպանում և մշակում ենք',
  the_data_we_store_and_process_text: `Անհրաժեշտության դեպքում Ծառայությունից օգտվելը հեշտացնելու և Ձեր անհատական պահանջներին
  հարմարեցնելու, խարդախության և չարաշահումների դեմ պայքարելու նպատակով և/կամ օրենքով
  պահանջվող դեպքերում մենք պահպանում ենք Ձեր տվյալները: Մենք կարող ենք ստանալ տվյալների
  հետևյալ տեսակները՝ անուն, հասցե, էլ.փոստի հասցե, հեռախոսահամար և գնումների
  պատմություն: Մենք բարեխիղճ ջանքեր ենք գործադրում տվյալների անվտանգ պահպանման համար,
  սակայն երաշխիքներ չենք կարող տալ: Դուք կարող եք մուտք գործել և թարմացնել Ձեր մասին
  որոշակի տվյալները Ձեր հաշվի լոգինի միջոցով:`,
  cookies: 'Cookie-ֆայլեր',
  cookies_text: `Մեր ծառայություններից օգտվելիս մենք կարող ենք փոքր ֆայլ ուղարկել Ձեր համակարգչին կամ
  սարքին («Cookie-ֆայլ»): Դա մեզ հնարավորություն կտա նույնականացնել Ձեր համակարգիչը,
  հետևել մեր կայքում Ձեր վարքագծին և բացահայտել Ձեր հետաքրքրությունների որոշակի
  ոլորտները, որպեսզի մենք կարողանանք անհատականացնել և հարմարեցնել մեր Ծառայությունները
  Ձեր նախապատվություններին: Մենք կարող ենք օգտագործել Cookie-ֆայլեր անձնական տվյալներ
  հավաքելու և պահելու համար, ինչպես նաև կարող ենք կապել Cookie-ֆայլերում պահված
  տվյալները Ձեր կողմից մեզ տրամադրած անձնական տվյալների հետ: Դուք կարող եք կարգավորել
  Ձեր բրաուզերը այնպես, որ այն մերժի Cookie-ֆայլերի օգտագործումը, սակայն դրա հերևանքով
  Ծառայության որոշ տարրերի օգտագործումը կբացառվի:`,
  data_deletion: 'Տվյալների ջնջում',
  data_deletion_text: `Մենք մեզ իրավունք ենք վերապահում ջնջել և ոչնչացնել Ձեր մասին հավաքագրված ամբողջ
  տեղեկատվությունը Ձեր հաշվի դադարեցումից հետո, եթե Դուք այլ բան չեք պահանջում: Դուք
  իրավունք ունեք պահանջել ջնջել Ձեր տվյալները: Կախված այն Ծառայություններից, որոնք մենք
  տրամադրել ենք Ձեզ, իրավական բնույթի նպատակներով մեզանից կարող է պահանջվել որոշակի
  տվյալներ պահպանել տվյալների ջնջման պահանջ ներկայացնելուց հետո հինգ տարվա ընթացքում:
  Մենք չենք կարող շարունակել Ձեզ տրամադրել մեր Ծառայությունը, եթե Դուք խնդրել եք ջնջել
  Ձեր տվյալները: Դուք կարող եք պահանջել ջնջել Ձեր տվյալները կապվելով մեր հաճախորդների
  սպասարկման կենտրոնի հետ:`,
  policy_updates: 'Քաղաքականության թարմացումներ',
  policy_updates_text: `Մենք մեզ իրավունք ենք վերապահում ժամանակ առ ժամանակ փոփոխել այս քաղաքականությունը: Մեր
  կողմից թարմացված քաղաքականությունը կներկայացվի մեր կայքում, և եթե Դուք շարունակում եք
  մուտք գործել ու օգտվել մեր Ծառայություններից՝ հետևելով այդ փոփոխություններին, ապա
  դրանով Դուք համաձայնում եք ենթարկվել մեր կողմից կատարված ցանկացած փոփոխությանը:`,
};
