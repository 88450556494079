import AOS from 'aos';
import * as Sentry from '@sentry/react';
import 'aos/dist/aos.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import Cookies from 'universal-cookie';

import Main from './main';
import './index.css';
import './i18n/i18n';
import { register } from './serviceWorkerRegistration';

const cookies = new Cookies();

export const locales = ['am', 'ru', 'en'];

AOS.init();

const root = document.getElementById('root');
const locale = cookies.get('locale');

if (!locale) {
  const browserLang = navigator.language?.slice(0, 2);
  if (locales.includes(browserLang)) {
    cookies.set('locale', browserLang, {
      path: '/',
      expires: new Date(Date.now() + 60 * 86400000),
    });
  }
}

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: Number(process.env.REACT_APP_TRACES_SAMPLE_RATE),
  });

  register();
  if ('registerProtocolHandler' in navigator) {
    navigator.registerProtocolHandler('web+gift', '/?gift=%s');
  }
}
if (root) {
  ReactDOM.createRoot(root).render(
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  );
}
