import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Congratulation } from '../../SpinTheWheel/Congratulation';
import LanguagePicker from '../../Helpers/Selectbox/LanguagePicker';
import Phone from '../../Helpers/icons/Phone';
import SpinWheel from '../../SpinTheWheel/SpinWheel';
import PrimaryPopup from '../../popups/PrimaryPopup';

type PropsType = {
  className?: string;
};

export default function TopBar({ className }: PropsType) {
  const [showSpinWheel, setShowSpinWheel] = useState(false);
  const [searchParams] = useSearchParams();
  const spinAndWinParam = searchParams.has('spin_and_win');
  const couponFromParam = searchParams.get('coupon');
  const showSpinWheelByParams = searchParams.has('widget') && !!couponFromParam;

  useEffect(() => {
    if (spinAndWinParam || showSpinWheelByParams) {
      setShowSpinWheel(true);
    }
  }, [spinAndWinParam, showSpinWheelByParams]);

  return (
    <>
      <div className={`w-full bg-white h-10 border-b border-qgray-border ${className || ''}`}>
        <div className='container-x mx-auto h-full'>
          <div className='flex justify-between items-center h-full'>
            <div className='topbar-nav'>
              <p className='text-xs'>
                <a
                  href='tel:+37493773701'
                  className='flex gap-[5px] items-center hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer'
                >
                  <Phone size='13px' /> +374 93 773 701
                </a>
              </p>
            </div>

            <div className='topbar-dropdowns flex gap-[16px] align-center'>
              {/*<button className='text-[13px]' onClick={() => setShowSpinWheel(true)}>*/}
              {/*  🎁 <span>Spin & Win</span>*/}
              {/*</button>*/}
              <div className='flex space-x-6'>
                <div className='language-select flex space-x-1 items-center'>
                  <LanguagePicker />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showSpinWheel && (
        <PrimaryPopup
          className='max-w-[700px] h-full sm:h-fit sm:rounded-2xl rounded-none sm:px-[18px] pt-[18px] sm:my-[20px]'
          onClose={() => setShowSpinWheel(false)}
          title=''
        >
          <div className='sm:mt-[-32px] pb-[40px]'>
            {showSpinWheelByParams ? <Congratulation coupon={couponFromParam} /> : <SpinWheel />}
          </div>
        </PrimaryPopup>
      )}
    </>
  );
}
