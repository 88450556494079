import React from 'react';
import { Provider } from 'react-redux';
import ScrollToTop from './components/Helpers/ScrollToTop';
import Alerts from './components/Alerts/Alerts';
import App from './App';
import { store } from './store/store';

function Main() {
  return (
    <Provider store={store}>
      <ScrollToTop />
      <App />
      <Alerts />
    </Provider>
  );
}

export default Main;
