import CountDownTimer from './CountDownTimer';
import { SendEmailForm } from './SendEmailForm';
import { CopyIcon } from '../Auth/Profile/icons/CopyIcon';
import { useAppDispatch } from '../../hooks/hooks';
import { showAlert } from '../../store/alertsReducer';

type Props = {
  coupon: string;
  discount?: string;
  nextSpinAt?: string | null;
};

export const Congratulation = ({ coupon, discount, nextSpinAt }: Props) => {
  const dispatch = useAppDispatch();
  const copyText = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => dispatch(showAlert({ alerts: 'Copied to clipboard', successType: true })));
  };

  return (
    <>
      <h1 className='text-3xl mb-[24px] text-center w-full'>
        Congratulations, You&apos;re a Winner!
      </h1>
      <div className='text-center mb-4'>
        You&apos;ve struck gold! You won a <b>{discount}</b> coupon:
        <div className='flex justify-center gap-2 w-full p-3'>
          <div className='rounded-lg border border-qgray-border w-fit px-3 py-1 align-center overflow-hidden font-bold text-2xl'>
            {coupon}
          </div>

          <button
            onClick={() => copyText(coupon)}
            className='rounded-lg border border-qgray-border p-2 aspect-square align-center overflow-hidden text-qgray hover:text-[#454545] duration-150'
          >
            <CopyIcon />
          </button>
        </div>
        {!!nextSpinAt && (
          <>
            Use it when buying new gift cards. Your next spin is available in:
            <b>
              <CountDownTimer targetDate={nextSpinAt} />
            </b>
            Get ready to keep the winning streak going!
          </>
        )}
      </div>

      <SendEmailForm coupon={coupon} />
    </>
  );
};
