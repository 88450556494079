import * as Sentry from '@sentry/react';
import { showAlert } from '../store/alertsReducer';
import { logout } from '../store/profileReducer';
import { AppDispatch } from '../store/store';

interface ErrorBox {
  validationErrors: { [key: string]: string } | null;
  errorMessage: string | null;
}

export const errorHandler = (error: any, dispatch: AppDispatch, cb?: (value: ErrorBox) => void) => {
  const result: ErrorBox = {
    validationErrors: null,
    errorMessage: null,
  };

  if (error?.response?.status === 422 && error.response.data?.errors) {
    result.validationErrors = {};
    const errors = { ...error.response.data.errors };
    Object.keys(errors).forEach((key) => {
      const val = errors[key][0];
      result.validationErrors = { ...result.validationErrors, [key]: val };
    });

    if (error.response.data.message) {
      result.errorMessage = error.response.data.message;
    }
  } else if (error?.response?.status >= 400 && error?.response?.status < 500) {
    dispatch(
      showAlert({
        alerts: error.response.data?.message || error.response.data.error,
        successType: false,
      })
    );

    if (error.response.status === 401) {
      dispatch(logout()).catch(() => false);
    }
  } else if (
    typeof error === 'object' &&
    error?.response?.status !== 0 &&
    process.env.REACT_APP_ENVIRONMENT === 'production'
  ) {
    Sentry.captureException(error, {
      extra: {
        message: 'captured in errorHandler',
        statusCode: JSON.stringify(error?.response?.status),
      },
    });
  } else {
    const message = error.response.data?.message
      ? error.response.data?.message
      : 'Something went wrong!';
    dispatch(showAlert({ alerts: message, successType: false }));
  }
  if (cb) return cb(result);

  return result;
};

export default errorHandler;
