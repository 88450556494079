import React, { useEffect, useState } from 'react';
import categoriesAPI from '../../API/categoriesAPI';
import Drawer from '../Mobile/Drawer';
import Footer from './Footers/Footer';
import Header from './Header';

type PropsType = {
  children: React.ReactNode;
  childrenClasses?: string;
};

export default function Layout({ children, childrenClasses }: PropsType) {
  const [drawer, setDrawer] = useState(false);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    categoriesAPI.getEvents().then((response) => {
      setEvents(response.data);
    });
  }, []);

  return (
    <>
      <Drawer events={events} open={drawer} action={() => setDrawer(!drawer)} />
      <div className='w-full overflow-x-hidden'>
        <Header events={events} drawerAction={() => setDrawer(!drawer)} />
        <div className={`w-full  ${childrenClasses || 'pt-6 pb-[48px] md:pb-[60px] pb-10'}`}>
          {children && children}
        </div>
        {/* <DiscountBanner /> */}
        <Footer />
      </div>
    </>
  );
}
