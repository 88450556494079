import React from 'react';
import { useCountdown } from '../../hooks/useCountDown';

type Props = {
  targetDate: string | Date;
};

const CountDownTimer = ({ targetDate }: Props) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  return (
    <div className='py-1'>
      {!!days && <>{days} Days : </>}
      {!!hours && <>{hours} Hours : </>}
      {minutes} Minutes : {seconds} Seconds
    </div>
  );
};

export default CountDownTimer;
