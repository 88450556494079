import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import alertReducer from './alertsReducer';
import profileReducer from './profileReducer';

export const store = configureStore({
  reducer: {
    alerts: alertReducer,
    profile: profileReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type DispatchResponse = { [key: string]: string } | string;

export type AppThunkType<R = Promise<void | DispatchResponse | any>> = ThunkAction<
  R,
  RootState,
  undefined,
  Action
>;
