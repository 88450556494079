import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

type PropsType = {
  className?: string;
  queryKey?: string;
  placeholder?: string | null;
  onSubmit: (value: string) => void;
};

export default function SearchBox({
  className,
  queryKey = 'keyword',
  placeholder,
  onSubmit,
}: PropsType) {
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState('');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const searchKeyword = searchParams.get(queryKey);
    setKeyword(searchKeyword || '');
  }, []);

  const handleSearch = async (e: FormEvent) => {
    e.preventDefault();

    onSubmit(keyword);
  };

  return (
    <>
      <form
        onSubmit={handleSearch}
        className={`w-full h-full flex items-center  border border-qgray-border bg-white rounded-lg ${
          className || ''
        }`}
      >
        <div className='flex-1 h-full'>
          <input
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            type='text'
            className='search-input'
            placeholder={placeholder || `${t('search')}...`}
          />
        </div>
        <div className='w-[1px] h-[22px] bg-qgray-border'></div>

        <button className='w-[93px] h-full text-sm font-600' type='submit'>
          {t('search')}
        </button>
      </form>
    </>
  );
}
