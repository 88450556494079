import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputCom from '../Helpers/InputCom';
import { useAppDispatch } from '../../hooks/hooks';
import { showAlert } from '../../store/alertsReducer';
import spinWheelAPI from '../../API/spinWheelAPI';

export const SendEmailForm = ({ coupon }: { coupon: string }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const sendEmail = () => {
    setIsLoading(true);
    spinWheelAPI
      .sendEmail(email, coupon)
      .then((res) => {
        dispatch(showAlert({ alerts: res.message, successType: true }));
        setEmail('');
      })
      .catch((err) => {
        setError(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    sendEmail();
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (error) {
      setError('');
    }
  };

  return (
    <div className='flex flex-col w-full items-center justify-center pt-[30px]'>
      <h2 className='pb-3 text-center text-[20px] font-medium text-qblacktext'>
        Enter Your Email and Activate Coupon
      </h2>
      <form
        onSubmit={handleSubmit}
        className='flex flex-col items-center gap-4  w-full sm:max-w-[500px]'
      >
        <InputCom
          label=''
          placeholder={`${t('email')}`}
          name='email'
          type='email'
          inputClasses='h-[50px]'
          value={email}
          inputHandler={onChange}
          errorMessage={error}
        />

        <button
          type='submit'
          className='w-full sm:max-w-[120px] px-[36px] py-[12px] black-btn text-sm text-white w-full max-h-[50px] font-semibold flex justify-center bg-purple items-center'
          disabled={isLoading}
        >
          <span>Activate</span>
        </button>
      </form>
    </div>
  );
};
