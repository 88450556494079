import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { getAnonymousUserId } from 'anonymous-user-id';
import { add } from 'date-fns';
import anonymousUserAPI from '../API/anonymousUserAPI';

const anonymousUserKeys = [
  'utm_medium',
  'utm_source',
  'utm_adgroup',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'gclid',
  'fbclid',
];

const cookies = new Cookies();

const useAnonymousUser = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [canSend, setCanSend] = useState(true);

  const sendUserData = (data: { [key: string]: string | null }, updateCookies: boolean) => {
    if (canSend) {
      const uri = `${window.location.origin}${window.location.pathname}`;
      setCanSend(false);
      anonymousUserAPI.storeUserData({ ...data, uri }).finally(() => {
        setCanSend(true);
      });
    }
    if (updateCookies) {
      cookies.set('anonymousUser', data, {
        path: '/',
        expires: add(new Date(), {
          minutes: 30,
        }),
      });
    }
  };

  useEffect(() => {
    const anonymousUserInCookie = cookies.get('anonymousUser');
    const requestDetails = {
      domain: window.location.host,
      userAgent: window.navigator.userAgent,
    };
    const anonymousUserId = getAnonymousUserId(Date.now().toString(), requestDetails);

    if (!anonymousUserInCookie) {
      const anonymousUser: { [key: string]: string | null } = {
        anonymous_user_id: anonymousUserId,
        referrer: document.referrer,
      };

      anonymousUserKeys.forEach((key) => {
        anonymousUser[key] = searchParams.get(key);
      });

      sendUserData(anonymousUser, true);
    } else {
      let cookiesMustUpdate = false;
      const anonymousUser = { ...anonymousUserInCookie, referrer: document.referrer };
      anonymousUserKeys.forEach((key) => {
        const paramInQuery = searchParams.get(key);
        if (paramInQuery && paramInQuery !== anonymousUser[key]) {
          anonymousUser[key] = paramInQuery;
          cookiesMustUpdate = true;
        }
      });
      sendUserData(anonymousUser, cookiesMustUpdate);
    }
  }, [`${location.pathname}${location.search}`]);
};

export default useAnonymousUser;
