import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Breadcrumb } from '../types/types';

type PropsType = {
  paths: Breadcrumb[];
};

export default function BreadcrumbCom({ paths }: PropsType) {
  const { t } = useTranslation();

  const breadcrumbPaths = !paths ? [{ name: t('home'), path: '/' }] : paths;

  return (
    <>
      {breadcrumbPaths && breadcrumbPaths.length > 0 && (
        <div className='breadcrumb-wrapper font-400 text-[14px] text-qblack mb-[24px]'>
          {breadcrumbPaths.map((path, idx) => (
            <span key={`${path.name}_${idx}`}>
              <NavLink to={path.path}>
                <span className='mx-1 capitalize'>{path.name}</span>
              </NavLink>
              <span className='sperator'>/</span>
            </span>
          ))}
        </div>
      )}
    </>
  );
}
