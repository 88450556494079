import React, { ReactNode } from 'react';

type InputComProps = {
  label: string;
  type?: string;
  name?: string;
  placeholder?: string;
  children?: ReactNode;
  inputHandler?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  inputClasses?: string;
  labelClasses?: string;
  disabled?: boolean;
  errorMessage?: string;
  autoComplete?: 'off' | 'on';
  autoFocus?: boolean;
};

export default function InputCom({
  label,
  type,
  name,
  placeholder,
  children,
  inputHandler,
  value,
  inputClasses,
  labelClasses,
  disabled,
  errorMessage,
  autoComplete = 'on',
  autoFocus,
}: InputComProps) {
  return (
    <div className='input-com w-full h-full'>
      {label && (
        <label
          className={`input-label capitalize block md:mb-2 mb-1 text-qgray text-[14px] font-normal ${
            labelClasses || ''
          } ${disabled ? 'opacity-50' : ''}`}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <div className='input-wrapper border border-qgray-border w-full h-full overflow-hidden relative '>
        <input
          placeholder={placeholder}
          value={value}
          onChange={inputHandler}
          className={`input-field placeholder:text-sm text-sm px-6
           text-dark-gray w-full font-normal bg-white focus:ring-0 focus:outline-none ${
             inputClasses || ''
           } ${disabled ? 'opacity-50' : ''}`}
          type={type}
          id={name}
          disabled={disabled}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
        />
        {children && children}
      </div>
      {errorMessage && <p className='text-sm text-rose-800 my-[5px] font-light'>{errorMessage}</p>}
    </div>
  );
}
