import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BreadcrumbCom from './BreadcrumbCom';
import Checkmark from './Checkmark';
import Layout from './Partials/Layout';

export default function Success() {
  const { t } = useTranslation();

  useEffect(() => {
    sessionStorage.removeItem('pendingProducts');
  }, []);

  return (
    <Layout childrenClasses='pt-0 pb-0'>
      <div className='single-product-wrapper w-full'>
        <div className='product-view-main-wrapper bg-white pt-[30px] w-full'>
          <div className='breadcrumb-wrapper w-full pb-[1px]'>
            <div className='container-x mx-auto'>
              <BreadcrumbCom paths={[{ name: t('home'), path: '/' }]} />
            </div>
          </div>
        </div>
        <div className='min-h-[348px] md:min-h-[50vh] w-full flex flex-col items-center py-[40px]'>
          <h1 className='mb-[32px] text-[20px] md:text-[24px] font-semibold'>
            {t('payment_successful')}
          </h1>
          <span className='w-[112px] h-[112px]'>
            <Checkmark />
          </span>
          <p className='text-sm md:text-lg mt-[35px] md:mt-[40px] max-w-[380px] text-center '>
            {t('payment_message')}
          </p>
        </div>
      </div>
    </Layout>
  );
}
