import { Fragment, useEffect, useState } from 'react';
import WheelComponent from 'react-wheel-of-prizes';
import Spinner from '../Helpers/Spinner';
import CountDownTimer from './CountDownTimer';

import spinWheelAPI from '../../API/spinWheelAPI';
import { useAppDispatch } from '../../hooks/hooks';
import { showAlert } from '../../store/alertsReducer';
import { SpinWheelItems, SpinWheelResponse, SpinWheelState } from '../../types/types';
import errorHandler from '../../utils/errorHandler';

import { Congratulation } from './Congratulation';
import './spin-wheel.css';

export default function SpinWheel() {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [wheelItems, setWheelItems] = useState<SpinWheelItems>([]);
  const [segmentLabels, setSegmentLabels] = useState<Array<string>>([]);
  const [segmentColors, setSegmentColors] = useState<Array<string>>([]);
  const [prise, setPrise] = useState<null | SpinWheelResponse>(null);
  const [nextSpinAt, setNextSpinAt] = useState<string | null>(null);
  const [spinWheelState, setSpinWheelState] = useState<SpinWheelState>(null);

  const handleSpinnerError = (error: any, showMessage?: boolean) => {
    if (error?.response?.status === 403) {
      const errorData: SpinWheelResponse = error.response?.data?.data;

      if (errorData?.next_spin_at) {
        setNextSpinAt(error.response?.data?.data?.next_spin_at);
      }

      if (errorData?.result) {
        setPrise(errorData);
        setSpinWheelState('won');
      }

      if (errorData?.result === null) {
        setPrise(null);
        setSpinWheelState('lost');
      }

      if (errorData?.message && showMessage) {
        dispatch(showAlert({ alerts: errorData.message, successType: false }));
      }
    } else {
      setSpinWheelState('error');

      errorHandler(error, dispatch);
    }
  };

  useEffect(() => {
    spinWheelAPI
      .getWheelItems()
      .then((response) => {
        setWheelItems(response.data);
        setSegmentLabels(response.data.map(() => 'Best of Luck'));
        setSpinWheelState('init');
        const colors = ['#EE4040', '#F0CF50', '#815CD1', '#3DA5E0', '#34A24F'];
        const segmentColorsArray: string[] = [];

        response.data.forEach(() => {
          const color = colors.shift();

          segmentColorsArray.push(color as string);

          colors.push(color as string);
        });

        setSegmentColors(segmentColorsArray);
      })
      .catch((error) => {
        handleSpinnerError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      setWheelItems([]);
      setSpinWheelState(null);
      setPrise(null);
    };
  }, []);

  const onFinished = () => {
    const item = wheelItems[Math.floor(Math.random() * wheelItems.length)];
    setIsLoading(true);

    spinWheelAPI
      .sendResult(item)
      .then((res) => {
        if (item === null) {
          setPrise(null);
          setSpinWheelState('lost');
        } else {
          setPrise(res);
          setSpinWheelState('won');
        }

        setNextSpinAt(res.next_spin_at);
      })
      .catch((error) => {
        handleSpinnerError(error, true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading)
    return (
      <div className='w-full min-h-[200px] relative'>
        <div className='wheel-loading'>
          <Spinner />
        </div>
      </div>
    );

  return (
    <div className='wheel-wrapper'>
      {spinWheelState === 'init' && (
        <Fragment key='init'>
          <h1 className='text-3xl my-[16px]'>Welcome to Spin & Win!</h1>
          <p className='text-center mb-4'>
            Give it a spin and see if luck is on your side. You could win exciting prizes with your
            very first spin!
          </p>
          <WheelComponent
            segments={segmentLabels}
            segColors={segmentColors}
            onFinished={onFinished}
            primaryColor='black'
            contrastColor='white'
            buttonText='Spin'
            downDuration={250}
            upDuration={40}
          />
        </Fragment>
      )}

      {spinWheelState === 'won' && !!prise?.result && (
        <Congratulation coupon={prise.result} discount={prise.discount} nextSpinAt={nextSpinAt} />
      )}

      {spinWheelState === 'lost' && !!nextSpinAt && (
        <div key='lost' className='w-full min-h-[80px] text-center'>
          <h1 className='text-3xl mb-[24px]'>Better Luck Next Time 🍀</h1>
          <p className='text-center mb-4'>
            You didn&apos;t win this time, but don&apos;t worry! Your next spin awaits in just{' '}
            <b>{!!nextSpinAt && <CountDownTimer targetDate={nextSpinAt} />}</b> Get ready to try
            your luck again and aim for those fantastic prizes!
          </p>
        </div>
      )}

      {spinWheelState === 'error' && (
        <div key='error' className='w-full min-h-[80px] text-center'>
          <h1 className='text-3xl mb-[24px]'>Something went wrong!</h1>
        </div>
      )}
    </div>
  );
}
