export const privacy_policy_content = {
  general_overview: 'General overview',
  general_overview_text: `At egiftcards.am, maintaining the privacy of your personal data is 
    important to us. For this reason, we are committed to protecting the privacy of the personal 
    data of all visitors and users of our website and our application ("Services"). Through this
     privacy policy ("Privacy Policy"), we describe how we collect your information, why we need
      it, and how we use it. Please read this privacy policy carefully as it contains important 
      information about how we process any personal data you provide to us.`,
  information_we_collect: 'Information We Collect and How We Use Your Information',
  information_we_collect_text: `We collect only the information necessary to provide and improve
      Our Services. We use your information to improve our Services and customize them to your preferences.
      This is necessary for us to be able to legally find out how our Services are being used, and to
      find and implement ways to develop and expand our business. It is also necessary for us to 
      fulfill our legitimate interests in improving our Services, improving efficiency, 
      increasing user interest in the Services, and gaining insights into usage patterns of our Services.
      In order to help you search for and find relevant information and products and services offered on egiftcards.am,
      including using that information to find the best ways for sellers to market and sell their products,
      it is very important to us to adapt to your individual requirements during the provision of our Services.`,
  to_send_you_information_about_improvements:
    'To send you information about improvements and new services developed for you',
  to_send_you_information_about_improvements_text: `We may send you messages and materials directly by e-mail and other
     means to inform you about new products, services, promotions and to provide you with information that we think will
      be of interest to you.`,
  for_the_safety_and_security: 'For the safety and security of our services',
  for_the_safety_and_security_text: `We use your information for safety and security purposes,
       which may include providing your data for such purposes. We do this because it is necessary
      to ensure the security of our Services, to prevent harm to individuals or property or to prevent crime,
      to exercise or protect legal rights, or to prevent harm to our systems or users, or to protect the
      legitimate interests of a third party. This includes protecting our community from spam, fraud,
      harassment, intellectual property infringement, crime and all types of security risks.
      We use your information for the purpose of providing and improving the Services, providing billing and payment,
      identity verification and authentication, and general research and summary reporting.`,
  information_collected_about_you: 'Information collected about you',
  information_collected_about_you_text: `When you use the Services or create a login and password to access the Services,
       you may be asked to provide information about yourself,
        including providing (i) your name and contact information, including email address and phone numbers;
         (ii) information that will allow us to verify and verify your identity;
          (iii) location data; (iv) data for invoicing and providing payment;
           and (v) such other data as we may require from time to time to provide the Services and to comply
            with applicable laws. This information is required to provide the Services to you.
             If you do not provide such information, it may cause us to delay or impede our provision of the Services.`,
  who_do_we_share_your_personal_data_with: 'Who do we share your personal data with?',
  who_do_we_share_your_personal_data_with_text: `We may provide all or part of the above data under the following
     circumstances: pursuant to a search warrant, court order, or other court order; To protect the rights, property,
      or safety of egiftcards.am users, egiftcards.am, or the general public; At your direction (for example, if you
           authorize us to provide data to other users).`,
  the_data_we_store_and_process: 'The data we store and process',
  the_data_we_store_and_process_text: `If necessary, we retain your data in order to facilitate the use
            of the Service and to adapt it to your individual requirements, to fight against fraud and abuse and/or
             in cases required by law. We may receive the following types of data: name, address, email address,
              phone number, and purchase history. We make good faith efforts to keep data secure, but cannot
               make guarantees. You can access and update certain information about you by logging in to your account.`,
  cookies: 'Cookies',
  cookies_text: `When you use our Services, we may send a small file to your computer or device ("Cookie"). 
      This will enable us to identify your computer, track your behavior on our site and
       identify specific areas of your interests so that we can personalize and tailor our Services
        to your preferences. We may use cookies to collect and store personal information, and may
         link information stored in cookies with personal information you provide to us.
          You can set your browser to reject the use of cookies, but this will prevent you from using
           certain elements of the Service.`,
  data_deletion: 'Data deletion',
  data_deletion_text: `We reserve the right to delete and destroy all information collected 
           about you upon termination of your account, unless you request otherwise. You have the right
            to request deletion of your data. Depending on the Services we have provided to you,
             we may be required for legal purposes to retain certain data for a period of five years after you submit
              a data deletion request. We cannot continue to provide you with our Service if you have requested to
               delete your data. You can request deletion of your data by contacting our customer service center.`,
  policy_updates: 'Policy updates',
  policy_updates_text: `We reserve the right to change this policy from time to time.
                Our updated policy will be posted on our website, and if you continue to access and use our Services 
                following those changes, you agree to be bound by any changes we make.`,
};
