import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Alert } from '../types/types';

const initialState = {
  alerts: [] as Alert[],
};

export const alertSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<Alert>) => {
      state.alerts = [action.payload, ...state.alerts];
    },
    removeAlert: (state, action: PayloadAction<string>) => {
      state.alerts = state.alerts.filter((alert) => alert.id !== action.payload);
    },
  },
});

export const { setAlert, removeAlert } = alertSlice.actions;

export const showAlert = createAsyncThunk(
  'alerts/showAlert',
  async (
    { alerts, successType }: { alerts: string[] | string; successType: boolean },
    thunkAPI
  ) => {
    const { dispatch } = thunkAPI;
    const idAlert = new Date().getMilliseconds().toString();

    if (Array.isArray(alerts)) {
      alerts.forEach((item, idx) => {
        if (item !== '') {
          dispatch(setAlert({ message: item, success: successType, id: idAlert + idx }));

          const idTimeOut = setTimeout(() => {
            dispatch(removeAlert(idAlert + idx));
            clearTimeout(idTimeOut);
          }, 3000);
        }
      });
    }
    if (typeof alerts === 'string') {
      if (alerts !== '') {
        dispatch(setAlert({ message: alerts, success: successType, id: idAlert }));

        const idTimeOut = setTimeout(() => {
          dispatch(removeAlert(idAlert));
          clearTimeout(idTimeOut);
        }, 3000);
      }
    }
  }
);

export default alertSlice.reducer;
