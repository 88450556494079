import React from 'react';
import { PUBLIC_URL } from '../utils/constants';

const LoadingScreen = () => {
  return (
    <div className='loading-screen top-[0] left-[0] fixed w-[100%] h-[100%] flex justify-center items-center z-[99999] bg-[#fff]'>
      <img width='100' height='100' src={`${PUBLIC_URL}/assets/images/logo.svg`} alt='logo' />
    </div>
  );
};

export default LoadingScreen;
