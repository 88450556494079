import requestInstance from './axios';

const profileAPI = {
  async getProfile() {
    const res = await requestInstance().get('/user');

    return res.data;
  },
};

export default profileAPI;
