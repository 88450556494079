import React, { ReactNode } from 'react';

type PropsType = {
  children?: ReactNode;
  onClose: () => void;
  title: string;
  className?: string;
};

const PrimaryPopup = ({ children, onClose, title, className = '' }: PropsType) => {
  return (
    <div className='w-full h-full fixed left-0 top-0 z-[100] flex justify-center overflow-y-auto'>
      <div
        onClick={onClose}
        className='w-full h-full fixed left-0 right-0 bg-black bg-opacity-25'
      ></div>
      <div
        data-aos='fade-up'
        className={`sm:h-[fit-content] w-full h-[fit-content] bg-white relative pt-[40px]
         px-[16px] md:px-[38px] md:my-[20px] rounded-2xl ${className}`}
        style={{ zIndex: '999' }}
      >
        <div className='title-bar flex items-center justify-between mb-[32px]'>
          <h6 className='text-2xl font-semibold'>{title}</h6>

          <span className='cursor-pointer' onClick={onClose}>
            <svg
              fill='black'
              height='24px'
              width='24px'
              version='1.1'
              id='Capa_1'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 460.775 460.775'
            >
              <path
                d='M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
  c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
  c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
  c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
  l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
  c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z'
              />
            </svg>
          </span>
        </div>
        {children}
      </div>
    </div>
  );
};

export default PrimaryPopup;
