import React from 'react';
import { useTranslation } from 'react-i18next';
import BreadcrumbCom from './BreadcrumbCom';
import Layout from './Partials/Layout';
import Exclamation from './Exclamation';

export default function Failure() {
  const { t } = useTranslation();

  return (
    <>
      <Layout childrenClasses='pt-0 pb-0'>
        <div className='single-product-wrapper w-full '>
          <div className='product-view-main-wrapper bg-white pt-[30px] w-full'>
            <div className='breadcrumb-wrapper w-full pb-[1px]'>
              <div className='container-x mx-auto'>
                <BreadcrumbCom paths={[{ name: t('home'), path: '/' }]} />
              </div>
            </div>
          </div>
          <div className='min-h-[50vh] w-full flex flex-col items-center pt-[30px]'>
            <h1 className='mb-[30px] text-2xl'> {t('payment_failed')}</h1>
            <Exclamation />

            <p className='text-lg mt-[40px] max-w-[380px] text-center'>
              {t('payment_failed_message')}
            </p>
          </div>
        </div>
      </Layout>
    </>
  );
}
