import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ThinPeople from '../../Helpers/icons/ThinPeople';
import SearchBox from '../../Helpers/SearchBox';
import { PUBLIC_URL } from '../../../utils/constants';
import LogoutPopup from '../../Auth/LogoutPopup';

type PropsType = {
  className?: string;
  isAuth: boolean;
  name?: string;
};

export default function Middlebar({ className, isAuth, name }: PropsType) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showLogout, setShowLogout] = useState(false);
  const showSearch = useLocation().pathname !== '/products';
  const onSearch = (keyword: string) => {
    navigate(`/products?keyword=${keyword}`);
  };

  return (
    <div className={`w-full h-[100px] bg-white ${className}`}>
      <div className='container-x mx-auto h-full'>
        <div className='relative h-full'>
          <div className='flex justify-between items-center h-full'>
            <div>
              <Link to='/' className='main-logo flex items-center gap-[18px]'>
                <img
                  width='48'
                  height='48'
                  className='mt-[-10px]'
                  src={`${PUBLIC_URL}/assets/images/logo.svg`}
                  alt='logo'
                />
                <div className='logo-text'>
                  <h5 className='text-[24px] font-semibold mb-[4px]'>E-Gift Cards</h5>
                  <p className='text-[10px] font-medium'>
                    The first digital gift cards platform in Armenia
                  </p>
                </div>
              </Link>
            </div>
            <div className='w-[480px] h-[44px]'>
              {showSearch && <SearchBox onSubmit={onSearch} className='search-com' />}
            </div>
            <div className='flex space-x-6 items-center'>
              <div>
                {isAuth ? (
                  <div className='relative group'>
                    <Link to='/profile' className='flex items-center gap-[10px]'>
                      <p className='text-[13px] max-w-[150px] line-clamp-2'>{name}</p>

                      <span>
                        <ThinPeople />
                      </span>
                    </Link>
                    <div
                      className='absolute hidden group-hover:block z-50 bg-white rounded-lg right-[-10px] p-2'
                      style={{ boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.2)' }}
                    >
                      <Link
                        to='/profile'
                        className='block px-2 py-[5px] hover:bg-[#ededed] rounded-lg whitespace-nowrap'
                      >
                        {t('profile')}
                      </Link>
                      <Link
                        to='/profile#dashboard'
                        className='block px-2 py-[5px] hover:bg-[#ededed] rounded-lg whitespace-nowrap'
                      >
                        {t('sent')}
                      </Link>
                      <Link
                        to='/profile#received'
                        className='block px-2 py-[5px] hover:bg-[#ededed] rounded-lg whitespace-nowrap'
                      >
                        {t('received')}
                      </Link>
                      <Link
                        to='/profile#coupons'
                        className='block px-2 py-[5px] hover:bg-[#ededed] rounded-lg whitespace-nowrap'
                      >
                        {t('coupons')}
                      </Link>
                      <Link
                        to='/profile#events'
                        className='block px-2 py-[5px] hover:bg-[#ededed] rounded-lg whitespace-nowrap'
                      >
                        {t('events')}
                      </Link>
                      <button
                        onClick={() => setShowLogout(true)}
                        className='w-full text-left block px-2 py-[5px] hover:bg-[#ededed] rounded-lg whitespace-nowrap'
                      >
                        {t('log_out')}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className='flex items-center gap-3'>
                    <Link to='/login'>{t('sign_in')}</Link>
                    <Link to='/signup'>{t('sign_up')}</Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showLogout && <LogoutPopup onClose={() => setShowLogout(false)} />}
    </div>
  );
}
