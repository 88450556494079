import React from 'react';
import { useAppSelector } from '../../hooks/hooks';

const Alerts = () => {
  const alerts = useAppSelector((state) => state.alerts.alerts);

  return (
    <div className='toast-container'>
      {alerts?.length > 0 &&
        alerts.map((item) => (
          <div
            key={item.id}
            className={`alert w-full text-white p-[12px] text-[14px] ${
              item.success ? 'bg-[#4ab663]' : 'bg-[#c61d1d]'
            }`}
          >
            {item.message}
          </div>
        ))}
    </div>
  );
};

export default Alerts;
