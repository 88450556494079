import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const requestInstance = (isAuthorized = true) =>
  axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/v1`,
    headers: isAuthorized
      ? {
          Authorization: `Bearer ${cookies.get('token')}`,
        }
      : {},
  });

export default requestInstance;
