import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import LoopIcon from '../icons/LoopIcon';

type PropsType = {
  handleSearch: (value: string) => void;
};

const MobileSearchBox = ({ handleSearch }: PropsType) => {
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState('');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const searchKeyword = searchParams.get('keyword');
    setKeyword(searchKeyword || '');
  }, []);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleSearch(keyword);
  };

  return (
    <form className='w-full' onSubmit={onSubmit}>
      <div className='search-bar w-full h-[34px]  flex '>
        <div className='flex-1 bg-white h-full border border-r-0 border-[#E9E9E9]'>
          <input
            type='text'
            className='w-full text-xs h-full focus:outline-none foucus:ring-0 placeholder:text-qgraytwo pl-2.5 '
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder={`${t('search')}...`}
          />
        </div>

        <button
          type='submit'
          className='w-[40px] h-full bg-qyellow flex justify-center items-center'
        >
          <span className='w-[23px] h-[23px]'>
            <LoopIcon />
          </span>
        </button>
      </div>
    </form>
  );
};

export default MobileSearchBox;
