import Cookies from 'universal-cookie';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  LoginCredentials,
  RegisterCredentials,
  ResetPasswordCredentials,
  SocialAuthCredentials,
  User,
} from '../types/types';
import { AppThunkType } from './store';
import authAPI from '../API/authAPI';
import profileAPI from '../API/profileAPI';
import errorHandler from '../utils/errorHandler';

const cookies = new Cookies();

const initialState = {
  user: null as User | null,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    cleanUp: (state) => {
      state.user = null;
    },
  },
});

export const { setUser, cleanUp } = profileSlice.actions;

export const getProfile = (): AppThunkType => async (dispatch) => {
  try {
    const resp = await profileAPI.getProfile();
    dispatch(setUser(resp.data));

    return Promise.resolve(resp.data);
  } catch (error) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const signUp =
  (values: RegisterCredentials): AppThunkType =>
  async (dispatch) => {
    try {
      const resp = await authAPI.signUp(values);
      dispatch(setUser(resp.data.user));

      cookies.set('token', resp.data.token, {
        path: '/',
        expires: new Date(Date.now() + 30 * 86400000),
      });

      return Promise.resolve(resp.data);
    } catch (error) {
      return errorHandler(error, dispatch, (errors) => Promise.reject(errors.validationErrors));
    }
  };

export const signIn =
  (credentials: LoginCredentials): AppThunkType =>
  async (dispatch) => {
    const { email, password } = credentials;
    try {
      const resp = await authAPI.signIn(email, password);
      dispatch(setUser(resp.data.user));

      cookies.set('token', resp.data.token, {
        path: '/',
        expires: new Date(Date.now() + 30 * 86400000),
      });

      return Promise.resolve(resp.data);
    } catch (error) {
      return errorHandler(error, dispatch, (errors) => Promise.reject(errors.validationErrors));
    }
  };

export const logout = (): AppThunkType => async (dispatch) => {
  try {
    const resp = await authAPI.logout();

    return Promise.resolve(resp.data);
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(cleanUp());

    cookies.remove('token', {
      path: '/',
    });
  }
};

export const forgotPassword =
  (email: string): AppThunkType =>
  async (dispatch) => {
    try {
      const resp = await authAPI.forgotPassword(email);

      return Promise.resolve(resp.message);
    } catch (error) {
      return errorHandler(error, dispatch, (errors) => Promise.reject(errors.validationErrors));
    }
  };

export const resetPassword =
  (values: ResetPasswordCredentials): AppThunkType =>
  async (dispatch) => {
    try {
      const resp = await authAPI.resetPassword(values);

      return Promise.resolve(resp.message);
    } catch (error) {
      return errorHandler(error, dispatch, (errors) => Promise.reject(errors.validationErrors));
    }
  };

export const socialAuth =
  ({ provider, token }: SocialAuthCredentials): AppThunkType =>
  async (dispatch) => {
    try {
      const response = await authAPI.socialAuth({ provider, token });
      const { data } = response;

      if (data?.token) {
        cookies.set('token', data.token, {
          path: '/',
          expires: new Date(Date.now() + 30 * 86400000),
        });
        dispatch(setUser(data.user));
      }

      return Promise.resolve(data);
    } catch (error) {
      errorHandler(error, dispatch);

      return false;
    }
  };

export default profileSlice.reducer;
