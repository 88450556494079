/* eslint-disable max-len */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { privacy_policy_content as privacy_policy_content_en } from './privacy_policy_en';
import { privacy_policy_content as privacy_policy_content_am } from './privacy_policy_am';

const resources = {
  en: {
    translation: {
      top_selling_products: 'Top Selling Products',
      new_arrivals: 'New Arrivals',
      companies: 'Companies',
      similar_products: 'Similar Services',
      buy: 'Buy',
      submit: 'Submit',
      apply: 'Apply',
      cancel: 'Cancel',
      view_more: 'View More',
      amd: 'AMD',
      price: 'Price',
      bonus: 'bonus',
      generate_qr: {
        sender_name: 'Sender Name',
        sender_email: 'Sender Email',
        sender_phone_number: 'Sender Phone Number',
        personal: 'Personal',
        recipient_name: 'Recipient Name',
        recipient_email: 'Recipient Email',
        message: 'Message',
        notify_at: 'Notify At',
        gift: 'Gift',
        for_me: 'For me',
        send_now: 'Send now',
        schedule: 'Schedule',
        recipient_phone_number: 'Recipient Phone Number',
        is_anonymous: 'Anonymous',
      },
      create_gift_card: 'Create Gift Card',
      home: 'Home',
      type: 'Type',
      event: 'Event',
      events: 'Events',
      relationships: 'Relationships',
      description: 'Description',
      company_info: 'Company info',
      company: 'Company',
      address: 'Address',
      phone: 'Phone',
      sign_up: 'Sign Up',
      sign_in: 'Sign In',
      alrady_have_an_account: 'Alrady have an Account?',
      create_account: 'Create Account',
      name: 'Name',
      email: 'Email',
      quantity: 'Quantity',
      created_at: 'Created At',
      password: 'password',
      repeat_password: 'Repeat password',
      dont_have_an_account: 'Don’t have an account?',
      forgot_password: 'Forgot password',
      log_out: 'Log Out',
      are_you_sure_logout: 'Are you sure you want to log out?',
      main_menu: 'Main Menu',
      sent_gift_cards: 'Sent Gift Cards',
      sent: 'Sent',
      received: 'Received',
      received_gift_cards: 'Received Gift Cards',
      show: 'Show',
      gift_card: 'Gift Card',
      code: 'Code',
      expires_at: 'Expires At',
      profile: 'Profile',
      search: 'Search',
      sold_cards: 'Sold Gift Cards',
      sort_by: 'Sort',
      price_desc: 'Price Desc',
      price_asc: 'Price Asc',
      become_a_partner: 'Become a Partner',
      partnership: 'Partnership',
      request_company: 'Request Company',
      first_name: 'First Name',
      last_name: 'Last Name',
      company_name: 'Company Name',
      send_request: 'Send a Request',
      contact: 'Contact',
      service: 'Service',
      order_number: 'Order Number',
      used_at: 'Used At',
      used: 'Used',
      not_used: 'Not used',
      download: 'Download',
      new_gift_card: 'New Gift Card',
      pay: 'Pay',
      continue: 'Continue',
      summary: 'Summary',
      e_gift_cards: 'E-Gift Cards',
      payment_successful: 'Payment Successful!',
      payment_message: "We're glad to inform you that we have confirmed your payment. Thank you!",
      payment_failed: 'Payment Failed',
      payment_failed_message: 'Your payment has not been received',
      yes: 'Yes',
      no: 'No',
      about_us: 'About Us',
      about_us_description: {
        title: 'Digital Gift-Cards Platform',
        content: `<h1><strong> What is E-Gift Cards?</strong></h1>
          <br/>
          <strong>E-Gift Cards</strong> is an innovative online platform for buying and selling digital gift cards. We provide a convenient online platform for both buyers and sellers, revolutionizing the way people give and receive gift cards.
          <br/>
          <br/>
          We understand the importance of finding the perfect gift for your loved ones. Through our platform, you get access to a wide selection of digital gift cards from Armenian brands. Whether you're looking for a birthday gift, a holiday gift, or just want to make your loved ones happy, you'll find it easily on our platform.
          <br/>
          <br/>
          Gift cards are sent by  <strong>email</strong> or <strong>SMS</strong>.
          <br/>
          Our platform cooperates with leading payment systems, <strong>iDram, Visa/Mastercard.</strong>
          <br/>
          <br/>
          <h2>Why choose <strong>E-Gift Cards</strong>?</h2>
          <br/>
          <strong>{{convenience}}</strong>: Our platform eliminates the need for physical gift cards by offering digital gift cards that are accessible from anywhere, anytime.
          <br/>
          <strong>{{speed}}</strong>: You no longer need to visit a store to buy a gift card. Get access to a large selection of gift cards, on one platform, and buy without leaving your home, at any time, in any country.
          <br/>
          <strong>{{variety}}</strong>: We have a huge collection of gift cards, which includes a wide variety of gift cards from well-known brands and small companies.
          <br/>
          <strong>{{security}}</strong>: We prioritize the security of your transactions by implementing robust security measures and encryption protocols. All gift cards are unique and cannot be copied.
          <br/>
          <br/>
          Join <strong>E-Gift Cards</strong> today and enjoy the convenience and flexibility of digital gift card shopping. Whether you are a buyer or seller, we strive to provide a safe and enjoyable platform that enhances the gifting experience for all involved.`,
        convenience: 'Convenience',
        speed: 'Speed',
        variety: 'Variety',
        security: 'Security',
      },
      how_it_works: 'How It Works',
      contact_us: 'Contact Us',
      our_address: 'Movses Khorenatsi 17, 0010, Yerevan, Armenia',
      online_gift_cards: 'E-Gift Cards',
      services: 'Services',
      gift_cards: 'Gift Cards',
      soon: 'Soon',
      showing_results: 'Showing {{firstItemNumber}}-{{lastItemNumber}} of {{total}} results',
      gift_card_is_valid_for: 'Gift Card is valid for {{expiresAt}} days',
      online: 'Online',
      sorry_page_not_found: 'Sorry, We can’t Find that page...',
      back_to_home: 'Back to Home',
      phone_number: 'Phone number',
      balance: 'Balance',
      messages: {
        become_a_partner_success: 'Your request has been successfully received!',
      },
      attention: 'Attention!',
      enter_your_phone_number: 'Enter your phone number for verification',
      preview: 'Preview',
      share: 'Share This',
      available_bonus: 'Available Bonus',
      privacy_policy: 'Privacy policy',
      terms_of_service: 'Terms of service',
      agree_with_policy: `I have read and agree to the <privacyPolicy>Privacy policy</privacyPolicy>
        {{and}}<termsOfService>Terms of service</termsOfService>`,
      and: 'and',
      special_offers: 'Special offers',
      learn_more: 'Learn more',
      buyers_passport: 'Buyer’s passport',
      upload_images: 'Upload Images',
      coupon: 'Coupon',
      search_company: 'Search Company',
      name_desc: 'By name Z-A',
      name_asc: 'By name A-Z',
      starting_from: 'Starting from',
      all_companies: 'Show All',
      latest_companies: 'Latest Companies',
      faq: 'Frequently Asked Questions',
      newsletter_subscription: 'Subscribe to our newsletter',
      support: 'Support',
      creating: {
        title: '1. Creating',
        description: 'Choose design, date and write a message',
      },
      receiving: {
        title: '2. Receiving',
        description: 'Gift Card will be sent immediately by email or SMS',
      },
      happiness: {
        title: '3. Happiness',
        description: 'Happy recipient using the Gift Card for shopping',
      },
      delete: 'Delete',
      edit: 'Edit',
      event_calendar: {
        title: 'Title',
        description: 'Description',
        not_chosen: 'Not chosen',
        remind: {
          title: 'Remind',
          day: '1 day before',
          week: '1 week before',
          month: '1 month before',
        },
        repeat: {
          title: 'Repeat',
          day: 'Every day',
          month: 'Every month',
          year: 'Every year',
        },
        celebrant_name: 'Celebrant name',
        celebrant_email: 'Celebrant email',
        new_event: 'New Event',
      },
      privacy_policy_content: privacy_policy_content_en,
      date: 'Date',
      coupons: 'Coupons',
      website: 'Website',
      notes: 'Additional Info',
      sale: 'Sale',
      in_sale: 'Sale',
      multiple_recipients: 'Multiple Recipients',
      upload: 'Upload',
      upload_multiple_recipients: 'Upload multiple recipients',
      recipients: 'Recipients',
      how_to_use_gift_card: 'How to use gift card',
    },
  },
  am: {
    translation: {
      top_selling_products: 'Լավագույն վաճառք',
      new_arrivals: 'Վերջին ավելացված',
      companies: 'Ընկերություններ',
      similar_products: 'Այլ Ծառայություններ',
      buy: 'Գնել',
      bonus: 'Բոնուս',
      submit: 'Հաստատել',
      apply: 'Օգտագործել',
      cancel: 'Չեղարկել',
      view_more: 'Տեսնել ավելին',
      amd: 'դրամ',
      price: 'Գին',
      generate_qr: {
        sender_name: 'Ձեր անունը',
        sender_email: 'Ձեր էլ. հասցեն',
        sender_phone_number: 'Ձեր հեռախոսահամարը',
        personal: 'Իմ համար',
        recipient_name: 'Ստացողի անուն',
        recipient_email: 'Ստացողի էլ. հասցե',
        message: 'Մաղթանք',
        notify_at: 'Երբ ուղարկել',
        gift: 'Նվեր',
        for_me: 'Ինձ համար',
        send_now: 'Ուղարկել հիմա',
        recipient_phone_number: 'Ստացողի հեռախոսահամար',
        schedule: 'Ընտրել օր',
        is_anonymous: 'Անանուն',
      },
      create_gift_card: 'Ստեղծել նվեր քարտ',
      home: 'Գլխավոր',
      type: 'Տեսակ',
      event: 'Առիթ',
      events: 'Առիթներ',
      relationships: 'Ում համար',
      description: 'Նկարագրություն',
      company_info: 'Ընկերության մասին',
      company: 'Ընկերություն',
      address: 'Հասցե',
      phone: 'Հեռախոս',
      sign_up: 'Գրանցվել',
      sign_in: 'Մուտք',
      alrady_have_an_account: 'Գրանցված ե՞ք',
      create_account: 'Գրանցվել',
      name: 'Անուն',
      email: 'Էլ. հասցե',
      quantity: 'Քանակ',
      created_at: 'Ստեղծված է',
      password: 'Գաղտնաբառ',
      repeat_password: 'Կրկնել գաղտնաբառը',
      dont_have_an_account: 'Գրանցված չե՞ք',
      forgot_password: 'Մոռացել եմ գաղտնաբառը',
      log_out: 'Դուրս Գալ',
      are_you_sure_logout: 'Համոզվա՞ծ եք, որ ցանկանում եք դուրս գալ:',
      main_menu: 'Մենյու',
      sent_gift_cards: 'Ուղարկած նվեր քարտեր',
      received_gift_cards: 'Ստացած նվեր քարտեր',
      sent: 'Ուղարկած',
      received: 'Ստացած',
      show: 'Դիտել',
      gift_card: 'Նվեր քարտ',
      code: 'Կոդ',
      expires_at: 'Լրանում է',
      profile: 'Անձնական էջ',
      search: 'Փնտրել',
      sold_cards: 'Նվիրած քարտեր',
      sort_by: 'Դասավորել',
      price_desc: 'Ըստ գնի նվազող',
      price_asc: 'Ըստ գնի աճող',
      become_a_partner: 'Դառնալ գործընկեր',
      partnership: 'Համագործակցություն',
      request_company: 'Առաջարկել ընկերություն',
      first_name: 'Անուն',
      last_name: 'Ազգանուն',
      company_name: 'Ընկերության անունը',
      send_request: 'Կատարել Հարցում',
      contact: 'Կապ մեզ հետ',
      service: 'Ծառայություն',
      order_number: 'Պատվերի համարը',
      used_at: 'Օգտագործված է',
      used: 'Օգտագործված',
      not_used: 'Օգտագործված չէ',
      download: 'Ներբեռնել',
      new_gift_card: 'Նոր Նվեր-Քարտ',
      pay: 'Վճարում',
      continue: 'Շարունակել',
      summary: 'Ընդհանուր',
      e_gift_cards: 'Էլեկտրոնային Նվեր-Քարտեր',
      payment_successful: 'Վճարումը կատարված է',
      payment_message: 'Ուրախ ենք տեղեկացնել ձեզ, որ ձեր վճարումը հաստատվել է: Շնորհակալություն',
      payment_failed: 'Վճարումը ձախողվել է',
      payment_failed_message: 'Ձեր վճարումը չի կատարվել',
      yes: 'Այո',
      no: 'Ոչ',
      about_us: 'Մեր մասին',
      about_us_description: {
        title: 'Էլեկտրոնային Նվեր-Քարտերի հարթակ',
        content: `<h1><strong> Ի՞նչ է E-Gift Cards-ը:</strong></h1>
        <br/>
        <strong>E-Gift Cards</strong>-ը նորարարական առցանց հարթակ է, որը նախատեսված է թվային (digital) նվեր քարտեր գնելու և վաճառելու համար: Մենք տրամադրում ենք հարմարավետ առցանց հարթակ ինչպես գնորդների, այնպես էլ վաճառողների համար՝ հեղափոխելով մարդկանց նվեր քարտերի տրամադրման և ստացման ձևը:
        <br/>
        <br/>
        Մենք հասկանում ենք ձեր սիրելիների համար կատարյալ նվեր գտնելու կարևորությունը: Մեր հարթակի միջոցով դուք հասանելիություն եք ստանում հայկական ապրանքանիշերի թվային նվեր քարտերի լայն ընտրանիին: Անկախ նրանից, թե դուք փնտրում եք ծննդյան նվեր, տոնական նվեր կամ պարզապես ցանկանում եք ուրախացնել մտերիմներին, մեր հարթակում հեշտությամբ կգտնեք այն:
        <br/>
        <br/>
        Նվեր քարտերը ուղարկվում են <strong>էլ. փոստի</strong> կամ <strong>SMS</strong>-ի միջոցով:.
        <br/>
        Մեր հարթակը համագործակցում է առաջատար վճարման համակարգերի հետ, <strong>iDram, Telcell Wallet, Visa/Mastercard.</strong>
        <br/>
        <br/>
        <h2>Ինչու՞ ընտրել <strong>E-Gift Cards</strong>-ը</h2>
        <br/>
        <strong>{{convenience}}</strong>․ Մեր հարթակը վերացնում է ֆիզիկական նվեր քարտերի անհրաժեշտությունը՝ առաջարկելով թվային նվեր քարտեր, որոնք հասանելի են ցանկացած վայրից և ցանկացած ժամանակ։
        <br/>
        <strong>{{speed}}</strong>. Այլևս կարիք չկա այցելել խանութ սրահ, նվեր քարտ գնելու համար: Ստացիր հասանելիություն նվեր քարտերի մեծ տեսականիին, մեկ հարթակում, և գնիր առանց տանից դուրս գալու, ցանկացած ժամի, ցանկացած երկրից:
        <br/>
        <strong>{{variety}}</strong>. Մենք ունենք նվեր քարտերի հսկայական հավաքածու, որը ներառում է հայտնի ապրանքանիշերի և փոքր ընկերությունների նվեր քարտերի  բազմազան տեսականի:
        <br/>
        <strong>{{security}}</strong>. Մենք առաջնահերթություն ենք տալիս ձեր գործարքների անվտանգությանը՝ կիրառելով անվտանգության ամուր միջոցներ և գաղտնագրման արձանագրություններ: Բոլոր նվեր քարտերը ունիկալ են և չեն կարող պատճենվել:
        <br/>
        <br/>
        Միացեք <strong>E-Gift Cards</strong>-ին այսօր և վայելեք թվային նվեր քարտերի գնումների հարմարավետությունն ու ճկունությունը: Անկախ նրանից՝ դուք գնորդ եք, թե վաճառող, մենք ձգտում ենք ապահովել ապահով և հաճելի հարթակ, որը կբարձրացնի նվերների փորձը բոլոր ներգրավվածների համար:`,
        convenience: 'Հարմարավետություն',
        speed: 'Արագություն',
        variety: 'Բազմազանություն',
        security: 'Անվտանգություն',
      },
      how_it_works: 'Ինչպե՞ս է աշխատում E-Gift Cards-ը',
      contact_us: 'Կապ մեզ հետ',
      our_address: 'Մովսես Խորենացի 17, 0010, Երևան',
      online_gift_cards: 'Օնլայն Նվեր-Քարտեր',
      services: 'Ծառայություններ',
      gift_cards: 'Նվեր Քարտեր',
      soon: 'Շուտով',
      showing_results: 'Ցուցադրված են {{firstItemNumber}}-{{lastItemNumber}}-ը {{total}}-ից',
      gift_card_is_valid_for: 'Նվեր-քարտը ուժի մեջ է {{expiresAt}} օր',
      online: 'Առցանց',
      sorry_page_not_found: 'Էջը չի գտնվել...',
      back_to_home: 'Վերադառնալ գլխավոր էջ',
      phone_number: 'Հեռախոսահամար',
      balance: 'Մնացորդ',
      messages: {
        become_a_partner_success: 'Ձեր հարցումը հաջողությամբ ընդունված է',
      },
      attention: 'Ուշադրություն`',
      enter_your_phone_number: 'Հաստատման համար` մուտքագրեք ձեր հեռախոսահամարը',
      preview: 'Նախադիտում',
      share: 'Կիսվել',
      available_bonus: 'Հասանելի բոնուս',
      privacy_policy: 'Գաղտնիության քաղաքականություն',
      terms_of_service: 'Պայմաններ և դրույթներ',
      agree_with_policy: `Ես կարդացել եմ և համաձայն եմ <privacyPolicy>Գաղտնիության քաղաքականության</privacyPolicy>
      {{and}}<termsOfService>Պայմանների ու դրույթների</termsOfService> հետ`,
      and: 'ու',
      special_offers: 'Հատուկ առաջարկներ',
      learn_more: 'Իմանալ ավելին',
      buyers_passport: 'Գնորդի անձնագիր',
      upload_images: 'Բեռնել նկարներ',
      coupon: 'Կուպոն',
      search_company: 'Փնտրել ընկերություն',
      name_desc: 'Ըստ անվան Ֆ-Ա',
      name_asc: 'Ըստ անվան Ա-Ֆ',
      starting_from: 'Սկսած',
      all_companies: 'Բոլորը',
      latest_companies: 'Վերջին ընկերությունները',
      faq: 'Հաճախ Տրվող Հարցեր',
      newsletter_subscription: 'Բաժանորդագրվեք մեր նորություններին',
      support: 'Սպասարկում',
      creating: {
        title: '1. Ստեղծում',
        description: 'Ընտրեք դիզայնը, ամսաթիվը և գրեք բարեմաղթանք',
      },
      receiving: {
        title: '2. Ստացում',
        description: 'Նվեր Քարտը անմիջապես կուղարկվի ստացողին էլ. փոստով կամ SMS-ով',
      },
      happiness: {
        title: '3. Ուրախություն',
        description: 'Ուրախ ստացողը օգտագործում է նվեր քարտը գնումներ կատարելիս',
      },
      delete: 'Հեռացնել',
      edit: 'Խմբագրել',
      event_calendar: {
        title: 'Առիթ',
        description: 'Նկարագրություն',
        not_chosen: 'Չնշված',
        remind: {
          title: 'Հիշեցնել',
          day: '1 օր առաջ',
          week: '1 շաբաթ առաջ',
          month: '1 ամիս առաջ',
        },
        repeat: {
          title: 'Կրկնել',
          day: 'ամեն օր',
          month: 'ամեն ամիս',
          year: 'ամեն տարի',
        },
        celebrant_name: 'Հոբելյարի անուն',
        celebrant_email: 'Հոբելյարի էլ. հասցե',
        new_event: 'Նոր առիթ',
      },
      privacy_policy_content: privacy_policy_content_am,
      date: 'Ամսաթիվ',
      coupons: 'Կուպոններ',
      website: 'Վեբկայք',
      notes: 'Հավելյալ տեղեկություն',
      sale: 'Զեղչ',
      in_sale: 'Զեղչված',
      multiple_recipients: 'Մի քանի ստացող',
      upload: 'Վերբեռնել',
      upload_multiple_recipients: 'Վերբեռնել մի քանի ստացողներ',
      recipients: 'Ստացող',
      how_to_use_gift_card: 'Ինչպես օգտագործել նվեր քարտը',
    },
  },
  ru: {
    translation: {
      top_selling_products: 'Полулярное',
      new_arrivals: 'Новинки',
      companies: 'Компании',
      similar_products: 'Похожие сервисы',
      buy: 'Купить',
      submit: 'Отправить',
      apply: 'Применить',
      cancel: 'Отменить',
      view_more: 'Больше',
      amd: 'драм',
      price: 'Цена',
      bonus: 'Бонус',
      generate_qr: {
        sender_name: 'Имя отправителя',
        sender_email: 'Эл. почта отправителя',
        sender_phone_number: 'Номер телефона отправителя',
        personal: 'Личный',
        recipient_name: 'Имя получателя',
        recipient_email: 'Эл. почта получателя',
        message: 'Сообщение',
        notify_at: 'Уведомлять',
        gift: 'Подарок',
        for_me: 'Для меня',
        send_now: 'Отправить сейчас',
        schedule: 'Выбрать день',
        recipient_phone_number: 'Номер телефона получателя',
        is_anonymous: 'Анонимно',
      },
      create_gift_card: 'Создать подарочную карту',
      home: 'Домой',
      type: 'Тип',
      event: 'Событие',
      events: 'События',
      relationships: 'Для кого',
      description: 'Описание',
      company_info: 'Информация о компании',
      company: 'Компания',
      address: 'Адрес',
      phone: 'Телефон',
      sign_up: 'Регистрация',
      sign_in: 'Войти',
      alrady_have_an_account: 'Есть аккаунт?',
      create_account: 'Создать аккаунт',
      name: 'Имя',
      email: 'Эл. почта',
      quantity: 'Количество',
      created_at: 'Создан',
      password: 'пароль',
      repeat_password: 'Повторить пароль',
      dont_have_an_account: 'У вас еще нет аккаунта?',
      forgot_password: 'Забыли пароль',
      log_out: 'Выйти',
      are_you_sure_logout: 'Вы уверены, что хотите выйти?',
      main_menu: 'Меню',
      sent_gift_cards: 'Отправленные подарочные карты',
      sent: 'Отправленные',
      received: 'Полученные',
      received_gift_cards: 'Полученные подарочные карты',
      show: 'Показать',
      gift_card: 'Подарочная карта',
      code: 'Код',
      expires_at: 'Истекает',
      profile: 'Профиль',
      search: 'Поиск',
      sold_cards: 'Проданные подарочные карты',
      sort_by: 'Сортировать',
      price_desc: 'По убыванию цены',
      price_asc: 'По возрастанию цены',
      become_a_partner: 'Стать партнером',
      partnership: 'Сотрудничество',
      request_company: 'Предложить компанию',
      first_name: 'Имя',
      last_name: 'Фамилия',
      company_name: 'Название компании',
      send_request: 'Отправить запрос',
      contact: 'Свяжитесь с нами',
      service: 'Услуга',
      order_number: 'Номер',
      used_at: 'Использовалась',
      used: 'Использованный',
      not_used: 'Неиспользованный',
      download: 'Скачать',
      new_gift_card: 'Новая подарочная карта',
      pay: 'Оплата',
      continue: 'Продолжить',
      summary: 'Итого',
      e_gift_cards: 'Электронные подарочные карты',
      payment_successful: 'Оплата прошла успешно!',
      payment_message: 'Мы рады сообщить вам, что мы подтвердили ваш платеж. Спасибо!',
      payment_failed: 'Платеж не прошел',
      payment_failed_message: 'Ваш платеж не получен',
      yes: 'Да',
      no: 'Нет',
      about_us: 'О нас',
      about_us_description: {
        title: 'Платформа электронных подарочных карт',
        content: `<h1><strong> Что такое E-Gift Cards?</strong></h1>
        <br/>
        <strong>E-Gift Cards</strong> — это инновационная онлайн-платформа для покупки и продажи цифровых подарочных карт. Мы предоставляем удобную онлайн-платформу как для покупателей, так и для продавцов, революционизируя способы дарения и получения подарочных карт.
        <br/>
        <br/>
        Мы понимаем, как важно найти идеальный подарок для ваших близких. Через нашу платформу вы получаете доступ к широкому выбору цифровых подарочных карт от армянских брендов. Если вы ищете подарок на день рождения, праздничный подарок или просто хотите порадовать своих близких, вы легко найдете его на нашей платформе.
        <br/>
        <br/>
        Подарочные карты отправляются по <strong>электронной почте</strong> или <strong>SMS</strong>.
        <br/>
        Наша платформа сотрудничает с ведущими платежными системами, <strong>iDram, Telcell Wallet, Visa/Mastercard.</strong>
        <br/>
        <br/>
        <h2>Почему <strong>E-Gift Cards</strong>?</h2>
        <br/>
        <strong>{{convenience}}</strong>: наша платформа устраняет необходимость в физических подарочных картах, предлагая цифровые подарочные карты, доступные из любого места и в любое время.
        <br/>
        <strong>{{speed}}</strong>: Вам больше не нужно посещать магазин, чтобы купить подарочную карту. Получите доступ к большому выбору подарочных карт на одной платформе и покупайте, не выходя из дома, в любое время и в любой стране.
        <br/>
        <strong>{{variety}}</strong>: у нас есть огромная коллекция подарочных карт, которая включает в себя широкий выбор подарочных карт от известных брендов и небольших компаний.
        <br/>
        <strong>{{security}}</strong>: мы уделяем первостепенное внимание безопасности ваших транзакций, внедряя надежные меры безопасности и протоколы шифрования. Все подарочные карты уникальны и не могут быть скопированы.
        <br/>
        <br/>
        Присоединяйтесь к <strong>E-Gift Cards</strong> сегодня и наслаждайтесь удобством и гибкостью покупок цифровых подарочных карт. Являетесь ли вы покупателем или продавцом, мы стремимся предоставить безопасную и приятную платформу, которая расширяет возможности подарков для всех участников.`,
        convenience: 'Удобство',
        speed: 'Скорость',
        variety: 'Разнообразие',
        security: 'Безопасность',
      },
      how_it_works: 'Как это работает',
      contact_us: 'Свяжитесь с нами',
      our_address: 'улица Мовсеса Хоренаци, 17, 0010, Ереван, Армения',
      online_gift_cards: 'Электронные подарочные карты',
      services: 'Услуги',
      gift_cards: 'Подарочные карты',
      soon: 'Скоро',
      showing_results: 'Показаны {{firstItemNumber}}-{{lastItemNumber}} из {{total}}',
      gift_card_is_valid_for: 'Подарочная карта действительна {{expiresAt}} дней',
      online: 'Онлайн',
      sorry_page_not_found: 'Извините, мы не можем найти эту страницу...',
      back_to_home: 'На главную',
      phone_number: 'Номер телефона',
      balance: 'Баланс',
      messages: {
        become_a_partner_success: 'Ваш запрос успешно получен!',
      },
      attention: 'Внимание!',
      enter_your_phone_number: 'Для верификации введите ваш номер телефона',
      preview: 'Предварительный просмотр',
      share: 'Поделится',
      available_bonus: 'Доступные бонусы',
      privacy_policy: 'Политика Конфиденциальности',
      terms_of_service: 'Условия использования',
      agree_with_policy: `Согласен с <privacyPolicy>Политикой Конфиденциальности</privacyPolicy>
      {{and}}<termsOfService>Условиями использования</termsOfService>`,
      and: 'и',
      special_offers: 'Специальные предложения',
      learn_more: 'Узнать больше',
      buyers_passport: 'Паспорт покупателя',
      upload_images: 'Загрузить изображения',
      coupon: 'Купон',
      search_company: 'Искать компанию',
      name_desc: 'По названию Ю-А',
      name_asc: 'По названию А-Ю',
      starting_from: 'Цена от',
      all_companies: 'Показать все',
      latest_companies: 'Последние компании',
      faq: 'Часто Задаваемые Вопросы',
      newsletter_subscription: 'Подпишитесь на наши новости',
      support: 'Поддержка',
      creating: {
        title: '1. Создание',
        description: 'Выберите дизайн, дату и напишите поздравление',
      },
      receiving: {
        title: '2. Получение',
        description: 'Подарок будет отправлен сразу в SMS или E-mail',
      },
      happiness: {
        title: '3. Радость',
        description: 'Счастливый получатель использует сертификат для оплаты покупок',
      },
      delete: 'Удалить',
      edit: 'Редактировать',
      event_calendar: {
        title: 'Событие',
        description: 'Описание',
        not_chosen: 'Не выбрано',
        remind: {
          title: 'Напоминание',
          day: 'За 1 день',
          week: 'За 1 неделю',
          month: 'За 1 месяц',
        },
        repeat: {
          title: 'Повтор',
          day: 'Каждый день',
          month: 'Каждый месяц',
          year: 'Каждый год',
        },
        celebrant_name: 'Именинник',
        celebrant_email: 'Эл. почта именинника',
        new_event: 'Новое событие',
        privacy_policy_content: privacy_policy_content_en,
        date: 'Дата',
        coupons: 'Купоны',
      },
      website: 'Веб-сайт',
      notes: 'Доп. информация',
      sale: 'Скидка',
      in_sale: 'Скидки',
      multiple_recipients: 'Несколько получателей',
      upload: 'Загрузить',
      upload_multiple_recipients: 'Загрузить несколько получателей',
      recipients: 'Получателей',
      how_to_use_gift_card: 'Как использовать подарочную карту',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'am',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
