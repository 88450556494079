import Cookies from 'universal-cookie';
import { RegisterCredentials, SocialAuthCredentials } from '../types/types';
import requestInstance from './axios';

const cookies = new Cookies();

const authAPI = {
  async signUp(values: RegisterCredentials) {
    const locale = cookies.get('locale') || 'am';

    const res = await requestInstance(false).post(
      '/register',
      {
        ...values,
        is_admin: false,
      },
      {
        params: { locale },
      }
    );

    return res.data;
  },

  async signIn(email: string, password: string) {
    const locale = cookies.get('locale') || 'am';

    const res = await requestInstance(false).post(
      '/login',
      {
        email,
        password,
      },
      {
        params: { locale },
      }
    );

    return res.data;
  },

  async logout() {
    const locale = cookies.get('locale') || 'am';

    const res = await requestInstance().get('/logout', {
      params: { locale },
    });

    return res.data;
  },

  async forgotPassword(email: string) {
    const locale = cookies.get('locale') || 'am';

    const res = await requestInstance(false).post(
      '/forgot-password',
      { email },
      {
        params: { locale },
      }
    );

    return res.data;
  },

  async resetPassword(data: RegisterCredentials) {
    const locale = cookies.get('locale') || 'am';

    const res = await requestInstance(false).post('/reset-password', data, {
      params: { locale },
    });

    return res.data;
  },

  async socialAuth(data: SocialAuthCredentials) {
    const locale = cookies.get('locale') || 'am';

    const res = await requestInstance(false).post('/social/auth', data, {
      params: { locale },
    });

    return res.data;
  },
};

export default authAPI;
